import React, { useState, useRef, useMemo, useEffect } from 'react'
import ReactDOM from 'react-dom'
import Menu from '../components/menu'
import MediaViewer from '../components/media-viewer'

import { Alert, Divider } from 'antd'

import { getClientId, getSessionId } from '../libs/util'
import { config } from '../config'

const clientId = getClientId()
const sessionId = getSessionId()

export default function Main() {
  const [ _resource, setResource ] = useState({})
  const _viewerWrapper = useRef( null )

  const tagId = useMemo( () => {
    const params = new URLSearchParams( window.location.search )

    if( params.get('tagId') ) {
      return params.get('tagId')
    } else {
      return null
    }
  }, [])

  const rotate = useMemo( () => {
    const params = new URLSearchParams( window.location.search )

    if( params.has('rotate') ) {
      return params.get('rotate')
    } else {
      return null
    }
  }, [])

  const domeCroppedArea = useMemo( () => {
    const params = new URLSearchParams( window.location.search )

    if( params.has('4kvr360_235_dome_cropped_area') ) {
      return params.get('4kvr360_235_dome_cropped_area')
    } else {
      return null
    }
  }, [])

  const resourceId = useMemo( () => {
    const params = new URLSearchParams( window.location.search )
    return params.get('resourceId')
  }, [])

  const forceList = useMemo( () => {
    const params = new URLSearchParams( window.location.search )
    return params.get('forceList') === 'true'
  }, [])

  useEffect( () => {
    const resource = config.resources.find( r => r.id === resourceId )
    if ( resource ) {
      setResource( resource )
    }
  }, [ resourceId ])

  useEffect(() => {
    if( !_viewerWrapper.current ) return

    ReactDOM.unmountComponentAtNode( _viewerWrapper.current )
    
    // 即座に render() すると、 unmount されないようなので、 setTimeout を使って
    // 遅延起動させる
    setTimeout( () => {
      if( _resource.id ) {
        ReactDOM.render(<>
        <MediaViewer { ..._resource } clientId={ clientId } sessionId={ sessionId } tagId={tagId} rotate={rotate} domeCroppedArea={domeCroppedArea}/>
        <Divider />
        </>, _viewerWrapper.current )
      }
    }, 1)
  }, [ _resource, tagId, rotate, domeCroppedArea ])
  
  return (
    <div className="Main">
      { !!resourceId  ? (
        <div>
          <div ref={_viewerWrapper}></div>
          { !!tagId && (
          <Menu {..._resource} forceList={forceList} setResource={setResource} tagId={tagId} />
          )}
        </div>
      ):(
        <Alert type="error" message="param `resourceId` is unset." showIcon />
      )}
    </div>
  )
}