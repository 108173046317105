import { createClient } from '@m-pipe/mediasoup-cast-client'
import EventEmitter from 'eventemitter3'

function _isNumber(value) {
   return typeof value === 'number' && isFinite(value);
}

function _parseRTCStatsReport( reports ) {
  try {
    const tmp = reports.map( item => {
      return Object.entries( item )
        .filter( ([key, obj]) => key.includes('RTCInboundRTP'))
        .map( ([key, obj]) => {
          if( obj.kind === 'video' ) {
            return {
              videoTimestamp: obj.timestamp,
              kind: obj.kind,
              bytesReceived: obj.bytesReceived,
              frameHeight: obj.frameHeight,
              frameWidth: obj.frameWidth,
              framesPerSecond: obj.framesPerSecond,
              framesDecoded: obj.framesDecoded,
              jitter: obj.jitter,
              packetsLost: obj.packetsLost,
              packetsReceived: obj.packetsReceived,
            }
          } else if( obj.kind === 'audio' ) {
            return {
              audioTimestamp: obj.timestamp,
              kind: obj.kind,
              bytesReceived: obj.bytesReceived,
              concealedSamples: obj.concealedSamples,
              totalSamplesReceived: obj.totalSamplesReceived,
              jitter: obj.jitter,
              packetsLost: obj.packetsLost,
              packetsReceived: obj.packetsReceived,
            }
          } else {
            return {}
          }
        })
    })
    const metrics = tmp.reduce( ( prev, [ curr ]) => {
      const _curr = {}
      if( curr.kind === 'video' ) {
        _curr.videoTimestamp       = _isNumber( curr.videoTimestamp )  ? curr.videoTimestamp : -1
        _curr.videoBytesReceived   = _isNumber( curr.bytesReceived )   ? curr.bytesReceived : -1
        _curr.frameHeight          = _isNumber( curr.frameHeight )     ? curr.frameHeight : -1
        _curr.frameWidth           = _isNumber( curr.frameWidth )      ? curr.frameWidth : -1
        _curr.framesPerSecond      = _isNumber( curr.framesPerSecond ) ? curr.framesPerSecond : -1
        _curr.framesDecoded        = _isNumber( curr.framesDecoded )   ? curr.framesDecoded : -1
        _curr.videoJitter          = _isNumber( curr.jitter )          ? curr.jitter : -1
        _curr.videoPacketsLost     = _isNumber( curr.packetsLost )     ? curr.packetsLost : -1
        _curr.videoPacketsReceived = _isNumber( curr.packetsReceived ) ? curr.packetsReceived : -1
      } else if( curr.kind === 'audio' ) {
        _curr.audioTimestamp       = _isNumber( curr.audioTimestamp )       ? curr.audioTimestamp : -1
        _curr.audioBytesReceived   = _isNumber( curr.bytesReceived )        ? curr.bytesReceived : -1
        _curr.concealedSamples     = _isNumber( curr.concealedSamples )     ? curr.concealedSamples : -1
        _curr.totalSamplesReceived = _isNumber( curr.totalSamplesReceived ) ? curr.totalSamplesReceived : -1
        _curr.audioJitter          = _isNumber( curr.jitter )               ? curr.jitter : -1
        _curr.audioPacketsLost     = _isNumber( curr.packetsLost )          ? curr.packetsLost : -1
        _curr.audioPacketsReceived = _isNumber( curr.packetsReceived )      ? curr.packetsReceived : -1
      }
      return Object.assign( {}, prev, _curr )
    }, {})

    return metrics
  } catch(err) {
    return null
  }
}

export default class MsClient extends EventEmitter {
  liveId = ''
  endpoint = ''
  audioOnly = false
  client = null
  _clientId = ''
  _sessionId = ''
  _programId = ''
  _audioSubscriber = null
  _videoSubscriber = null

  constructor( props ) {
    super()

    if( 
      typeof props === 'object'
    ) {
      this.audioOnly = !!props.audioOnly
      this._clientId = props.clientId
      this._sessionId = props.sessionId
      this._programId = props.programId
    }
  }

  get publishers() {
    return this.client.availablePublishers
  }

  /**
   * getLiveStream
   * 
   * @snipet
   * ```js
   * const client = new MsClient()
   * const stream = await client.getLiveStream( endpoint, liveId )
   * 
   * video.srcObject = stream
   * ```
   * 
   * @param {string} endpoint - websoket endpoint
   * @param {string} liveId - live id
   * @returns {Promise<MediaStream>}
   * 
   */
  getLiveStream = async ( endpoint, liveId  ) => {
    this.endpoint = endpoint
    this.liveId = liveId

    let tracks = []
    try {
      await this._connect()

      this.client.on( 'disconnected', () => {
        this.emit('disconnected')
      })

      for( const publisher of this.publishers ) {
        const subscriber = await this._subscribe( publisher )
        const { rtpReceiver, track, kind } = subscriber

        rtpReceiver.playoutDelayHint = 0.3
        rtpReceiver.jitterBufferDelayHint = 0.3
        tracks.push( track )
        
        if( kind === 'video' ) {
          this._videoSubscriber = subscriber
        } else if( kind === 'audio' ) {
          this._audioSubscriber = subscriber
        }
      }
    } catch( err ) {
      throw err
    }

    return new MediaStream( tracks )
  }

  getMetrics = async () => {
    if( !this._audioSubscriber || !this._videoSubscriber ) return null

    const audioStats = await this._audioSubscriber.getStats()
    const videoStats = await this._videoSubscriber.getStats()

    const reports = []
    for( const [ key, entry ] of audioStats.entries() ) {
      if( key.includes('RTCInboundRTP') || entry.type === 'inbound-rtp' ) {
        reports.push({ AudioRTCInboundRTP: entry })
      }
    }
    for( const [ key, entry ] of videoStats.entries() ) {
      if( key.includes('RTCInboundRTP') || entry.type === 'inbound-rtp' ) {
        reports.push({ VideoRTCInboundRTP: entry })
      }
    }

    const metrics = Object.assign(
      { 
        id: this._programId,
        isLocation: false,
        clientId: this._clientId, 
        sessionId: this._sessionId,  
        latitude: 0,
        longitude: 0,
        accuracy: 0,
        angleNum: 0
      },
      _parseRTCStatsReport( reports )
    )
    return metrics
  }

  _connect = async () => {
    this.client = createClient({
      liveId: this.liveId,
      wsEndpoint: this.endpoint
    })
    await this.client.connect()
  }

  _subscribe = async publisher => {
    return await this.client.subscribe( publisher.id )
  }

  pause = async () => {
    try {
      for( const p of this.publishers ) {
        await this.client.pause({ id: p.id })
      }
    } catch(err) {
      console.error( `ms-client:pause - ${err.message}`)
    }
  }

  resume = async () => {
    try {
      for( const p of this.publishers ) {
        await this.client.resume({ id: p.id })
      }
    } catch(err) {
      console.error( `ms-client:resume - ${err.message}`)
    }
  }

  disconnect() {
    this.client.disconnect()
  }
}