export const entrypoint = 'https://suzuka2022.m-pipe.net'

export const config = {
  tags: [
    {
      id: 'dkzzfn6',
      name: 'all',
      type: 'admin',
      defaultList: true
    },
    {
      id: 'aSWu1q7E',
      name: 'ROOKIE Racing #32',
      defaultList: true
    },
    {
      id: 'nZF81qsf',
      name: 'ROOKIE Racing #28',
      defaultList: true
    },
    {
      id: 'brxwwh4',
      name: 'G/MOTION #60',
      defaultList: true
    },
  ],
  resources: [
    {
      id: 'frj-1',
      type: 'ils',
      name: 'FRJ',
      description: 'FRJ',
      endpoint: 'wss://sink-stai.prod.ils.aws.m-pipe.net',
      liveId: 'u26c6pcotnibd3qf',
      tagIds: '',
      misc: ['4kvr360_235_dome_mode']
    },
    {
      id: 'ils-1',
      type: 'ils',
      name: '360度 test(川)',
      description: 'SkyWay ILS のテスト配信映像です。360度ビューワー対応映像です。',
      endpoint: 'wss://sink.prod.ils.aws.m-pipe.net',
      liveId: 'zpidukebik8wq26s',
      tagIds: ''
    },
    {
      id: 'ils-2',
      type: 'ils',
      name: 'ドコモ低遅延VR検証用',
      description: 'ドコモ社の低遅延VR検証用のテストページです',
      endpoint: 'wss://sink.prod.ils.aws.m-pipe.net',
      liveId: 'ltlz5xacrbx86duh',
      tagIds: ''
    },
    {
      id: 'ils-235-1',
      type: 'ils',
      name: '235度低遅延VR検証用',
      description: 'KODAK PIXPRO 4KVR360 の235度ドームモードの低遅延VR検証用のテストページです。',
      endpoint: 'wss://sink-stai.prod.ils.aws.m-pipe.net',
      liveId: 'tjy2ubhddqty2iwe',
      tagIds: '',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-32',
      type: 'ils',
      name: 'スーパー耐久 #32',
      description: 'スーパー耐久 #32',
      endpoint: 'wss://sink-stai.prod.ils.aws.m-pipe.net',
      liveId: 'onz7svjxruzha7w2',
      tagIds: 'aSWu1q7E',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-32-0727-qualifying',
      type: 'hls',
      name: '#32 オートポリス 7/27 予選',
      description: '#32 オートポリス 7/27 予選',
      src: 'https://ils-public.s3.ap-northeast-1.amazonaws.com/stai/12126e7kaiycolt1/32/0727-qualifying/index.m3u8',
      tagIds: 'aSWu1q7E',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-32-0421-final',
      type: 'hls',
      name: '#32 SUGO 4/21 決勝',
      description: '#32 SUGO 4/21 決勝',
      src: 'https://ils-public.s3.ap-northeast-1.amazonaws.com/stai/12126e7kaiycolt1/32/0421-final/index.m3u8',
      tagIds: 'aSWu1q7E',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-32-0421-qualifying',
      type: 'hls',
      name: '#32 SUGO 4/21 予選',
      description: '#32 SUGO 4/21 予選',
      src: 'https://ils-public.s3.ap-northeast-1.amazonaws.com/stai/12126e7kaiycolt1/32/0421-qualifying/index.m3u8',
      tagIds: 'aSWu1q7E',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-28',
      type: 'ils',
      name: 'スーパー耐久 #28',
      description: 'ORC ROOKIE GR86 CNF concept',
      endpoint: 'wss://sink-stai.prod.ils.aws.m-pipe.net',
      liveId: 'oh1o2uciw88vl1nm',
      tagIds: 'nZF81qsf',
      misc: ['disable_360']
    },
    {
      id: 'ils-stai-28-0728-final',
      type: 'hls',
      name: '#28 オートポリス 7/28 決勝',
      description: '#28 オートポリス 7/28 決勝',
      src: 'https://ils-public.s3.ap-northeast-1.amazonaws.com/stai/12126e7kaiycolt1/28/0728-final/index.m3u8',
      tagIds: 'nZF81qsf',
      misc: ['disable_360']
    },
    {
      id: 'ils-stai-28-0727-qualifying',
      type: 'hls',
      name: '#28 オートポリス 7/27 予選',
      description: '#28 オートポリス 7/27 予選',
      src: 'https://ils-public.s3.ap-northeast-1.amazonaws.com/stai/12126e7kaiycolt1/28/0727-qualifying/index.m3u8',
      tagIds: 'nZF81qsf',
      misc: ['disable_360']
    },
    {
      id: 'ils-stai-28-0421-final',
      type: 'hls',
      name: '#28 SUGO 4/21 決勝',
      description: '#28 SUGO 4/21 決勝',
      src: 'https://ils-public.s3.ap-northeast-1.amazonaws.com/stai/12126e7kaiycolt1/28/0421-final/index.m3u8',
      tagIds: 'nZF81qsf',
      misc: ['disable_360']
    },
    {
      id: 'ils-stai-28-0421-qualifying',
      type: 'hls',
      name: '#28 SUGO 4/21 予選',
      description: '#28 SUGO 4/21 予選',
      src: 'https://ils-public.s3.ap-northeast-1.amazonaws.com/stai/12126e7kaiycolt1/23/0421-qualifying/index.m3u8',
      tagIds: 'nZF81qsf',
      misc: ['disable_360']
    },
    {
      id: 'ils-0',
      type: 'ils',
      name: 'Big buck bunny',
      description: 'SkyWay ILS のテスト配信映像です。360度ビューワー対応映像ではないため、歪んで表示されます。',
      endpoint: 'wss://sink-stai.prod.ils.aws.m-pipe.net',
      liveId: 'jok3y96jirzh0jf2'
    },
    /*
    {
      id: 'fuji-20230223-2',
      type: 'video',
      name: 'S耐公式テスト2',
      description: 'S耐公式テスト2',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/2023-02-23-fuji-test-2.mp4',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'fuji-20230223-night',
      type: 'video',
      name: 'S耐夜間練習走行',
      description: 'S耐夜間練習走行',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/2023-02-23-fuji-test-night.mp4',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },

    // 
    // ############### S耐久シリーズ本戦アーカイブ #32 ###############
    //
    {
      id: 'ils-stai-32-1112-final',
      type: 'hls',
      name: 'S耐第7戦富士 #32 決勝 11月12日',
      description: 'S耐第7戦富士 #32 決勝 11月12日',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-7-fuji/32/1112-final/index.m3u8',
      tagIds: 'xs4UCLFR',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-32-1111-qualifying',
      type: 'hls',
      name: 'S耐第7戦富士 #32 予選 11月11日',
      description: 'S耐第7戦富士 #32 予選 11月11日',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-7-fuji/32/1111-qualifying/index.m3u8',
      tagIds: 'xs4UCLFR',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-32-0903-final',
      type: 'hls',
      name: 'S耐茂木 #32 決勝',
      description: 'S耐茂木 #32 決勝',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-5-motegi/32/0903-final/index.m3u8',
      tagIds: 'xs4UCLFR',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-32-0902-qualifying',
      type: 'hls',
      name: 'S耐茂木 #32 予選',
      description: 'S耐茂木 #32 予選',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-5-motegi/32/0902-qualifying/index.m3u8',
      tagIds: 'xs4UCLFR',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-32-0730',
      type: 'hls',
      name: 'S耐オートポリス #32 決勝 7月30日',
      description: 'S耐オートポリス #32 決勝 7月30日',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-4-ap/32/0730-final-4/index.m3u8',
      tagIds: 'xs4UCLFR',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-32-0729',
      type: 'hls',
      name: 'S耐オートポリス #32 予選 7月29日',
      description: 'S耐オートポリス #32 予選 7月29日',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-4-ap/32/0730-qualifying/index.m3u8',
      tagIds: 'xs4UCLFR',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-32-0709-final',
      type: 'hls',
      name: 'S耐菅生 #32 決勝',
      description: 'S耐菅生 #32 決勝',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-3-sugo/32/0709-final/index.m3u8',
      tagIds: 'xs4UCLFR',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-32-0708-yosen',
      type: 'hls',
      name: 'S耐菅生 #32 予選',
      description: 'S耐菅生 #32 予選',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-3-sugo/32/0708-yosen/index.m3u8',
      tagIds: 'xs4UCLFR',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-32-0708-free',
      type: 'hls',
      name: 'S耐菅生 #32 フリー走行',
      description: 'S耐菅生 #32 フリー走行',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-3-sugo/32/0708-free/index.m3u8',
      tagIds: 'xs4UCLFR',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-32-0527-4',
      type: 'hls',
      name: 'S耐富士 #32 決勝4 5月27日',
      description: 'S耐富士 #32 決勝4 5月27日',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-2-fuji/32/0527-final-7/index.m3u8',
      tagIds: 'aSWu1q7E',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-32-0527-3',
      type: 'hls',
      name: 'S耐富士 #32 決勝3 5月27日',
      description: 'S耐富士 #32 決勝3 5月27日',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-2-fuji/32/0527-final-5/index.m3u8',
      tagIds: 'xs4UCLFR',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-32-0527-2',
      type: 'hls',
      name: 'S耐富士 #32 決勝2 5月27日',
      description: 'S耐富士 #32 決勝2 5月27日',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-2-fuji/32/0527-final-2/index.m3u8',
      tagIds: 'xs4UCLFR',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-32-0527-1',
      type: 'hls',
      name: 'S耐富士 #32 決勝1 5月27日',
      description: 'S耐富士 #32 決勝1 5月27日',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-2-fuji/32/0527-final-1/index.m3u8',
      tagIds: 'xs4UCLFR',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-32-0526',
      type: 'hls',
      name: 'S耐富士 #32 予選 5月26日',
      description: 'S耐富士 #32 予選 5月26日',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-2-fuji/32/0526-qualifying/index.m3u8',
      tagIds: 'xs4UCLFR',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-32-0317',
      type: 'video',
      name: 'S耐鈴鹿 #32 占有走行 3月17日',
      description: 'S耐鈴鹿 #32 占有走行 3月17日',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/ex6boul8/stai-32-0317-1test-1.mp4',
      tagIds: 'xs4UCLFR',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-32-0318-free',
      type: 'video',
      name: 'S耐鈴鹿 #32 フリー走行 3月18日',
      description: 'S耐鈴鹿 #32 フリー走行 3月18日',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-1-suzuka/32/0318-free.mp4',
      tagIds: 'xs4UCLFR',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-32-0318-qualifying',
      type: 'video',
      name: 'S耐鈴鹿 #32 予選',
      description: 'S耐鈴鹿 #32 予選',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-1-suzuka/32/0318-qualifying/index.mp4',
      tagIds: 'xs4UCLFR',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-32-0319-final',
      type: 'video',
      name: 'S耐鈴鹿 #32 決勝',
      description: 'S耐鈴鹿 #32 決勝',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-1-suzuka/32/0319-final/index.mp4',
      tagIds: 'xs4UCLFR',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },

    // 
    // ############### S耐久シリーズ本戦アーカイブ #28 ###############
    // 
    {
      id: 'ils-stai-28-1112-final',
      type: 'hls',
      name: 'S耐第7戦富士 #28 決勝 11月12日',
      description: 'S耐第7戦富士 #28 決勝 11月12日',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-7-fuji/28/1112-final/index.m3u8',
      tagIds: 'o2o5U5PR',
      misc: ['disable_360']
    },
    {
      id: 'ils-stai-28-1111-qualifying',
      type: 'hls',
      name: 'S耐第7戦富士 #28 予選 11月11日',
      description: 'S耐第7戦富士 #28 予選 11月11日',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-7-fuji/28/1111-qualifying/index.m3u8',
      tagIds: 'o2o5U5PR',
      misc: ['disable_360']
    },
    {
      id: 'ils-stai-28-0903-final',
      type: 'hls',
      name: 'S耐茂木 #28 決勝',
      description: 'S耐茂木 #28 決勝',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-5-motegi/28/0903-final/index.m3u8',
      tagIds: 'o2o5U5PR',
      misc: ['disable_360']
    },
    {
      id: 'ils-stai-28-0902-qualifying',
      type: 'hls',
      name: 'S耐茂木 #28 予選',
      description: 'S耐茂木 #28 予選',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-5-motegi/28/0902-qualifying/index.m3u8',
      tagIds: 'o2o5U5PR',
      misc: ['disable_360']
    },
    {
      id: 'ils-stai-28-0730',
      type: 'hls',
      name: 'S耐オートポリス #28 決勝 7月30日',
      description: 'S耐オートポリス #28 決勝 7月30日',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-4-ap/28/0730-final/index.m3u8',
      tagIds: 'o2o5U5PR',
      misc: ['disable_360']
    },
    {
      id: 'ils-stai-28-0729',
      type: 'hls',
      name: 'S耐オートポリス #28 予選 7月29日',
      description: 'S耐オートポリス #28 予選 7月29日',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-4-ap/28/0730-qualifying/index.m3u8',
      tagIds: 'o2o5U5PR',
      misc: ['disable_360']
    },
    {
      id: 'ils-stai-28-0709-final',
      type: 'hls',
      name: 'S耐菅生 #28 決勝',
      description: 'S耐菅生 #28 決勝',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-3-sugo/28/0709-final/index.m3u8',
      tagIds: 'o2o5U5PR',
      misc: ['disable_360']
    },
    {
      id: 'ils-stai-28-0708-yosen',
      type: 'hls',
      name: 'S耐菅生 #28 予選',
      description: 'S耐菅生 #28 予選',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-3-sugo/28/0708-yosen/index.m3u8',
      tagIds: 'o2o5U5PR',
      misc: ['disable_360']
    },
    {
      id: 'ils-stai-28-0527-final-2',
      type: 'hls',
      name: 'S耐富士 #28 決勝2 5月27日',
      description: 'S耐富士 #28 決勝2 5月27日',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-2-fuji/28/0527-final-2/index.m3u8',
      tagIds: 'o2o5U5PR',
      misc: ['disable_360']
    },
    {
      id: 'ils-stai-28-0527-final-1',
      type: 'hls',
      name: 'S耐富士 #28 決勝1 5月27日',
      description: 'S耐富士 #281 決勝 5月27日',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-2-fuji/28/0527-final-1/index.m3u8',
      tagIds: 'o2o5U5PR',
      misc: ['disable_360']
    },
    {
      id: 'ils-stai-28-0526-qualifying',
      type: 'hls',
      name: 'S耐富士 #28 予選 5月26日',
      description: 'S耐富士 #28 予選 5月26日',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-2-fuji/28/0526-qualifying/index.m3u8',
      tagIds: 'o2o5U5PR',
      misc: ['disable_360']
    },
    {
      id: 'ils-stai-28-0318-free',
      type: 'video',
      name: 'S耐鈴鹿 #28 フリー走行 3月18日',
      description: 'S耐鈴鹿 #28 フリー走行 3月18日',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-1-suzuka/28/0318-free.mp4',
      tagIds: 'o2o5U5PR',
      misc: ['disable_360']
    },
    {
      id: 'ils-stai-28-0318-qualifying',
      type: 'video',
      name: 'S耐鈴鹿 #28 予選',
      description: 'S耐鈴鹿 #28 予選',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-1-suzuka/28/0318-qualifying/index.mp4',
      tagIds: 'o2o5U5PR',
      misc: ['disable_360']
    },
    {
      id: 'ils-stai-28-0319-final',
      type: 'video',
      name: 'S耐鈴鹿 #28 決勝',
      description: 'S耐鈴鹿 #28 決勝',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-1-suzuka/28/0319-final-2/index.mp4',
      tagIds: 'o2o5U5PR',
      misc: ['disable_360']
    },
    */

    // 
    // ############### S耐久シリーズ本戦アーカイブ #60 ###############
    // 
    /*
    {
      id: 'ils-stai-60-1112-final',
      type: 'hls',
      name: 'S耐第7戦富士 #60 決勝 11月12日',
      description: 'S耐第7戦富士 #60 決勝 11月12日',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-7-fuji/60/1112-final/index.m3u8',
      tagIds: 'brxwwh4',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-60-1111-qualifying',
      type: 'hls',
      name: 'S耐第7戦富士 #60 予選 11月11日',
      description: 'S耐第7戦富士 #60 予選 11月11日',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-7-fuji/60/1111-qualifying/index.m3u8',
      tagIds: 'brxwwh4',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-60-1022-final',
      type: 'hls',
      name: 'S耐岡山 #60 決勝',
      description: 'S耐岡山 #60 決勝',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-6-okayama/60/1022-final/index.m3u8',
      tagIds: 'brxwwh4',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-60-0902-final-test2',
      type: 'hls',
      name: '#60 オンボードカメラ 14:40〜14:50',
      description: '#60 オンボードカメラ 14:40〜14:50',
      src: '',
      tagIds: 'brxwwh4',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-60-0318-free',
      type: 'video',
      name: 'S耐鈴鹿 #60 フリー走行 3月18日',
      description: 'S耐鈴鹿 #60 フリー走行 3月18日',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-1-suzuka/60/0318-free.mp4',
      tagIds: 'brxwwh4',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-60-0318-qualifying',
      type: 'video',
      name: 'S耐鈴鹿 #60 予選',
      description: 'S耐鈴鹿 #60 予選',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-1-suzuka/60/0318-qualifying/index.mp4',
      tagIds: 'brxwwh4',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-60-0319-final',
      type: 'video',
      name: 'S耐鈴鹿 #60 決勝',
      description: 'S耐鈴鹿 #60 決勝',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-1-suzuka/60/0319-final/index.mp4',
      tagIds: 'brxwwh4',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'tsukuba-20221003-vr360',
      type: 'video',
      name: '筑波 2022-10-03 VR360',
      description: 'VR360 2022/10/3 筑波サーキット検証前日に撮影した、ホテル -> サーキットの撮影映像です',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/2022-10-03-tsukuba-vr360.mp4'
    },
    {
      id: 'tsukuba-20221004-vr360-1st',
      type: 'video',
      name: '筑波 2022-10-04 VR360 1st',
      description: 'VR360 2022/10/4 筑波サーキットにて撮影した、オンボード映像です(1回目。映像調整デフォルト',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/2022-10-04+tsukuba-1st.mp4',
      tagIds: 'brxwwh4',
    },
    {
      id: 'tsukuba-20221004-vr360-2nd',
      type: 'video',
      name: '筑波 2022-10-04 VR360 2nd',
      description: 'VR360 2022/10/4 筑波サーキットにて撮影した、オンボード映像です(2回目。映像調整 EV -1.7',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/2022-10-04+tsukuba-2nd.mp4',
      tagIds: 'brxwwh4',
    },
    {
      id: 'tsukuba-20221003-kokutele',
      type: 'video',
      name: '筑波 2022-10-03 kokutele',
      description: 'kokutele(non 360VR) 2022/10/3 筑波サーキット検証前日に撮影した、ホテル -> サーキットのExperia(Simulcast) WebRTCインジェスト映像です',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/2022-10-03+tsukuba-experia-kokutele.mp4'
    },
    {
      id: 'tsukuba-20221004-kokutele',
      type: 'video',
      name: '筑波 2022-10-04 kokutele',
      description: 'kokutele(non 360VR) 2022/10/4 筑波サーキット検証日に撮影した、ホテル -> サーキットのExperia(non Simulcast) WebRTCインジェスト映像です',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/2022-10-04+tsukuba-kokutele.mp4'
    },
    {
      id: 'okayama-20221014-gr2-0',
      type: 'video',
      name: '岡山 2022-10-14 Super耐久 STEL 専有走行 Gr2 #60',
      description: '2022/10/14 岡山国際サーキットで撮影した、S耐 STEL 専有走行 Gr2(13:50-14:50) #60 の360VR車載配信映像です',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/20221014-okayama60-friday.mp4',
      tagIds: 'brxwwh4',
    },
    {
      id: 'okayama-20221015-gr2-0',
      type: 'video',
      name: '岡山 2022-10-15 Super耐久 フリー走行 Gr2 #60',
      description: '2022/10/15 岡山国際サーキットで撮影した、フリー走行 Gr2(9:10-9:50) #60 の360VR車載配信映像です',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/okayama2022/20221015-okayama60-sat-am.mp4',
      tagIds: 'brxwwh4',
    },
    {
      id: 'okayama-20221015-gr2-1',
      type: 'video',
      name: '岡山 2022-10-15 Super耐久 公式予選 Gr2 #60',
      description: '2022/10/15 岡山国際サーキットで撮影した、公式予選 Gr2(9:10-9:50) #60 の360VR車載配信映像です',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/okayama2022/20221015-okayama60-sat-pm.mp4',
      tagIds: 'brxwwh4',
    },
    {
      id: 'okayama-20221016-gr2-0',
      type: 'video',
      name: '岡山 2022-10-16 Super耐久 Gr2決勝 #60',
      description: '2022/10/16 岡山国際サーキットで撮影した、Gr2 決勝レース(8:30 - 11:30) #60 の360VR車載配信映像です',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/okayama2022/2022-10-16-okayama60-sun.mp4',
      tagIds: 'brxwwh4',
    },
    {
      id: 'suzuka-20221028-tcrj-0',
      type: 'video',
      name: '鈴鹿TCRJ 2022-10-28 鈴鹿TCRJ フリー走行 atack-1',
      description: '2022/10/28 鈴鹿サーキットで撮影した、TCRJ フリー走行 atack 1 の360VR車載配信映像です',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/2022-10-28-03-30-11-suzuka-tcrj.mp4',
      reverse: true
    },
    {
      id: 'suzuka-20221028-tcrj-1',
      type: 'video',
      name: '鈴鹿TCRJ 2022-10-28 鈴鹿TCRJ フリー走行 atack-2',
      description: '2022/10/28 鈴鹿サーキットで撮影した、TCRJ フリー走行 atack 2 の360VR車載配信映像です',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/2022-10-28-06-17-31-suzuka-tcrj2.mp4',
      reverse: true
    },
    {
      id: 'suzuka-20221028-tcrj-2',
      type: 'video',
      name: '鈴鹿TCRJ 2022-10-28 鈴鹿TCRJ フリー走行 atack-3',
      description: '2022/10/28 鈴鹿サーキットで撮影した、TCRJ フリー走行 atack 3 の360VR車載配信映像です',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/2022-10-28-06-37-56-suzuka-tcrj3.mp4',
      reverse: true
    },
    {
      id: 'suzuka-20221028-tcrj-3',
      type: 'video',
      name: '鈴鹿TCRJ 2022-10-28 鈴鹿TCRJ フリー走行 atack-4',
      description: '2022/10/28 鈴鹿サーキットで撮影した、TCRJ フリー走行 atack 4 の360VR車載配信映像です',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/2022-10-28-06-43-19-suzuka-tcrj4.mp4',
      reverse: true
    },
    {
      id: 'suzuka-20221029-tcrj-0',
      type: 'video',
      name: '鈴鹿TCRJ 2022-10-29 鈴鹿TCRJ Saturday シリーズ atack-1',
      description: '2022/10/29 鈴鹿サーキットで撮影した、TCRJ Saturday シリーズ atack 1 の360VR車載配信映像です',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/2022-10-28-22-48-40-suzuka-tcrj1.mp4',
      reverse: true
    },
    {
      id: 'suzuka-20221029-tcrj-1',
      type: 'video',
      name: '鈴鹿TCRJ 2022-10-29 鈴鹿TCRJ Saturday シリーズ atack-2',
      description: '2022/10/29 鈴鹿サーキットで撮影した、TCRJ Saturday シリーズ atack 2 の360VR車載配信映像です',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/2022-10-28-23-43-11-suzuka-tcrj2.mp4',
      reverse: true
    },
    {
      id: 'suzuka-20221029-tcrj-2',
      type: 'video',
      name: '鈴鹿TCRJ 2022-10-29 鈴鹿TCRJ Saturday シリーズ atack-3',
      description: '2022/10/29 鈴鹿サーキットで撮影した、TCRJ Saturday シリーズ atack 3 の360VR車載配信映像です',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/2022-10-29-02-28-25-suzuka-tcrj3.mp4',
      reverse: true
    },
    */
    /*
    {
      id: 'suzuka-20221030-tcrj-0',
      type: 'video',
      name: '鈴鹿TCRJ 2022-10-30 鈴鹿TCRJ Sunday シリーズ',
      description: '2022/10/30 鈴鹿サーキットで撮影した、TCRJ Sunday シリーズ の360VR車載配信映像です',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/2020-10-30-suzuka-tcrj.mp4',
      reverse: true
    },
    */
    /*
    {
      id: 'suzuka-20221125-stai-60',
      type: 'video',
      name: '鈴鹿S耐 2022-11-25 Super耐久 フリー走行 #60',
      description: '2022/11/25 鈴鹿サーキットで撮影した、フリー走行(13:15 - 15:15) #60 の360VR車載配信映像です',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/suzuka-stai-11-27/2022-11-25-stai-60-free.mp4',
      tagIds: 'brxwwh4',
    },
    {
      id: 'suzuka-20221126-stai-32-free',
      type: 'video',
      name: '鈴鹿S耐 2022-11-26 Super耐久 フリー走行 #32',
      description: '2022/11/26 鈴鹿サーキットで撮影した、フリー走行(9:30 - 10:30) #32 の360VR車載配信映像です',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/suzuka-stai-11-27/2022-11-26-stai-32-free.mp4',
      tagIds: 'xs4UCLFR',
    },
    {
      id: 'suzuka-20221126-stai-60-free',
      type: 'video',
      name: '鈴鹿S耐 2022-11-26 Super耐久 フリー走行 #60',
      description: '2022/11/26 鈴鹿サーキットで撮影した、フリー走行(9:30 - 10:30) #60 の360VR車載配信映像です',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/suzuka-stai-11-27/2022-11-26-stai-60-free.mp4',
      tagIds: 'brxwwh4',
    },
    {
      id: 'suzuka-20221126-stai-32-qualify-2k',
      type: 'video',
      name: '鈴鹿S耐 2022-11-26 Super耐久 予選走行 #32(2K)',
      description: '2022/11/26 鈴鹿サーキットで撮影した、予選走行 #32 の360VR車載配信映像です',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/suzuka-stai-11-27/2022-11-26-stai-32-qualify-2k.mp4',
      tagIds: 'xs4UCLFR',
    },
    {
      id: 'suzuka-20221126-stai-32-qualify-4k',
      type: 'video',
      name: '鈴鹿S耐 2022-11-26 Super耐久 予選走行 #32(4K)',
      description: '2022/11/26 鈴鹿サーキットで撮影した、予選走行 #32 の360VR車載配信映像です',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/suzuka-stai-11-27/2022-11-26-stai-32-qualify-2k.mp4',
      tagIds: 'xs4UCLFR',
    },
    {
      id: 'suzuka-20221126-stai-60-qualify',
      type: 'video',
      name: '鈴鹿S耐 2022-11-26 Super耐久 予選走行 #60(2K)',
      description: '2022/11/26 鈴鹿サーキットで撮影した、予選走行 #60 の360VR車載配信映像です',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/suzuka-stai-11-27/2022-11-26-stai-60-qualify-2k.mp4',
      tagIds: 'brxwwh4',
    },
    {
      id: 'suzuka-20221127-stai-32-race-2k',
      type: 'video',
      name: '鈴鹿S耐 2022-11-27 Super耐久 本選 #32(2K)',
      description: '2022/11/27 鈴鹿サーキットで撮影した、本選 #32 の360VR車載配信映像です(2K)',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/suzuka-stai-11-27/2022-11-27-stai-32-race-2k.mp4',
      tagIds: 'xs4UCLFR',
    },
    {
      id: 'suzuka-20221127-stai-32-race-4k',
      type: 'video',
      name: '鈴鹿S耐 2022-11-27 Super耐久 本選 #32(4K)',
      description: '2022/11/27 鈴鹿サーキットで撮影した、本選 #32 の360VR車載配信映像です(4K)',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/suzuka-stai-11-27/2022-11-27-stai-32-race-2k.mp4',
      tagIds: 'xs4UCLFR',
    },
    {
      id: 'suzuka-20221127-stai-60-race',
      type: 'video',
      name: '鈴鹿S耐 2022-11-27 Super耐久 本選 #60(2K)',
      description: '2022/11/27 鈴鹿サーキットで撮影した、本選 #60 の360VR車載配信映像です',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/suzuka-stai-11-27/2022-11-27-stai-60-race-2k.mp4',
      tagIds: 'brxwwh4',
    },
    */
    {
      id: 'camera-0',
      type: 'camera',
      name: 'カメラ',
      description: 'local camera からの映像を表示します。360 度カメラを用いない場合、歪んで表示されます。'
    },
    {
      id: 'video-1',
      type: 'video',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/sample2.mp4',
      name: 'CG 素材#0',
      description: 'CG素材映像です。360度対応映像。'
    },
    {
      id: 'video-2',
      type: 'video',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/sample3.mp4',
      name: 'CG 素材#1',
      description: 'CG素材映像です。360 度対応映像。'
    },
    {
      id: 'mie-tsu-niezakiura',
      type: 'video',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/mie-tsu-niezakiura.mp4',
      name: '三重県 津市 贄崎浦',
      description: '贄崎海水浴場の海岸です。360 度対応映像。',
      misc: ['rotate=x2']
    },
    {
      id: 'video-tamachi-360',
      type: 'video',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/2023-01-19-tamachi-360.mp4',
      name: '',
      description: '',
    },
    {
      id: 'video-tamachi-235',
      type: 'video',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/2023-01-19-tamachi-235.mp4',
      name: '',
      description: '',
      misc: ['4kvr360_235_dome_mode'],
    },
    {
      id: 'video-tamachi-235-cropped',
      type: 'video',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/2023-01-19-tamachi-235-cropped.mp4',
      name: '',
      description: '',
      misc: ['4kvr360_235_dome_mode', '4kvr360_235_dome_cropped_area=x0.5,y1'],
    },
    {
      id: 'hls',
      type: 'hls',
      src: 'https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.ism/.m3u8',
      name: '',
      description: '',
      links: [
        {
          url: '?resourceId=hls-live',
          text: 'ライブ',
        },
      ],
      misc: [],
    },
    {
      id: 'hls-live',
      type: 'hls',
      src: 'https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8',
      name: '',
      description: '',
      links: [
        {
          url: '?resourceId=hls',
          text: 'アーカイブ',
        },
      ],
      misc: [],
    },
    /*
    {
      id: 'ils-stai-32-0708-free',
      type: 'hls',
      name: 'S耐菅生 #32 フリー走行',
      description: 'S耐菅生 #32 フリー走行',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-3-sugo/32/0708-free/index.m3u8',
      tagIds: 'xs4UCLFR',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-28-0708-yosen',
      type: 'hls',
      name: 'S耐菅生 #28 予選',
      description: 'S耐菅生 #28 予選',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-3-sugo/28/0708-yosen/index.m3u8',
      tagIds: 'o2o5U5PR',
      misc: ['disable_360']
    },
    {
      id: 'ils-stai-32-0708-yosen',
      type: 'hls',
      name: 'S耐菅生 #32 予選',
      description: 'S耐菅生 #32 予選',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-3-sugo/32/0708-yosen/index.m3u8',
      tagIds: 'xs4UCLFR',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-28-0709-final',
      type: 'hls',
      name: 'S耐菅生 #28 決勝',
      description: 'S耐菅生 #28 決勝',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-3-sugo/28/0709-final/index.m3u8',
      tagIds: 'o2o5U5PR',
      misc: ['disable_360']
    },
    {
      id: 'ils-stai-32-0709-final',
      type: 'hls',
      name: 'S耐菅生 #32 決勝',
      description: 'S耐菅生 #32 決勝',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-3-sugo/32/0709-final/index.m3u8',
      tagIds: 'xs4UCLFR',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    {
      id: 'ils-stai-28-0902-qualifying',
      type: 'hls',
      name: 'S耐茂木 #28 予選',
      description: 'S耐茂木 #28 予選',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-5-motegi/28/0902-qualifying/index.m3u8',
      tagIds: 'o2o5U5PR',
      misc: ['disable_360']
    },
    {
      id: 'ils-stai-32-0902-qualifying',
      type: 'hls',
      name: 'S耐茂木 #32 予選',
      description: 'S耐茂木 #32 予選',
      src: 'https://assets360.s3.ap-northeast-1.amazonaws.com/stai/round-5-motegi/32/0902-qualifying/index.m3u8',
      tagIds: 'xs4UCLFR',
      misc: ['4kvr360_235_dome_mode', 'rotate=y180']
    },
    */
  ]
}
