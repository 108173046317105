import React, { useMemo } from 'react'
import { Button, List, Typography, Tag } from 'antd'

import {
  FaVideo,
  FaRegFileImage,
  FaRegFileVideo,
  FaCamera
} from 'react-icons/fa'

import {
  MdLiveTv
} from 'react-icons/md'

import { config } from '../config'

const { Title, Paragraph } = Typography

const getCover = res => {
  let ret

  switch( res.type ) {
  case 'image':
    ret = (
      <div>
        <FaRegFileImage style={{ fontSize: "3em" }} />
      </div>
    )
    break;
  case 'video':
    ret = (
      <div>
        <FaRegFileVideo style={{ fontSize: "3em" }} />
      </div>
    )
    break;
  case 'hls':
    ret = (
      <div>
        <MdLiveTv style={{ fontSize: "3em" }} />
      </div>
    )
    break;
  case 'camera':
    ret = (
      <div>
        <FaCamera style={{ fontSize: "3em" }} />
      </div>
    )
    break;
  case 'ils':
    ret = (
      <div>
        <FaVideo style={{ fontSize: "3em" }} />
      </div>
    )
    break;
  default:
    ret = (
    <div>
      type:<strong>{res.type}</strong> is not supported
    </div>)
  }

  return ret
}

export default function Menu( props ) {
  const { id, setResource, tagId, forceList } = props

  const dataSource = useMemo( () => {
    const tag = config.tags.find( item => item.id === tagId )

    if( tag.type === 'admin' ) {
      return config.resources
    } else {
      return config.resources.filter( item => item.tagIds && item.tagIds.includes(tagId) )
    }
  }, [ tagId ])

  return (
    <div className="Menu">
      { ( config.tags.find( item => item.id === tagId ).defaultList || forceList ) && (
        <div>
          <div>
            <Title level={4}>Media List</Title>
            <Paragraph>
              360度ビューワーで表示するメディアを選択してください
            </Paragraph>
          </div>
          <div>
            <Tag color='#108ee9'>{ config.tags.find( item => item.id === tagId ).name}</Tag>
            <List
              itemLayout='horizontal'
              dataSource={ dataSource }
              renderItem={ item => (
                <List.Item
                  actions={[<Button type='primary' size='small' onClick={() => setResource(item)}>watch</Button>]}
                >
                  <List.Item.Meta
                    avatar={ getCover( item )}
                    title={ (
                      <div>
                        <span>{ ( item.type === 'ils' || item.type === 'camera' ) ? <Tag color="#f50">LIVE</Tag> : <Tag color="#87d068">ARCHIVE</Tag> }</span>&nbsp;
                        <span>{ id === item.id ? <strong>{item.name}</strong> : item.name }</span>
                      </div>
                    )}
                    description={item.description}
                  />
                </List.Item>
              )}
            />
          </div>
        </div>
      )}
    </div>
  )
}