import React from 'react'

import logo from '../assets/211015_docomobusiness_rgb_color.png'



export default function Header() {
  return (
    <div className="Header">
      <header>
        <div className="container">
          <img height={42} src={logo} alt="logo"/>
        </div>
      </header>
    </div>
  )
}