import React from 'react'

import { 
  Button, 
  Tooltip,
  Select, 
  Switch,  
} from 'antd'

import { 
  IoMdVolumeHigh, 
  IoMdVolumeOff 
} from 'react-icons/io'
import {
  CgArrowLeftR,
  CgArrowRightR
} from 'react-icons/cg'
import { Md360 } from 'react-icons/md'
import { FaCamera } from 'react-icons/fa'
import { 
  RiFullscreenLine, 
  RiFullscreenExitLine 
} from 'react-icons/ri'

import "./media-controller.css"

const { Option } = Select

const styleBtn = {
  height: "100%",
  color: "#fff"
}

export default function MediaController( props ) {
  const {
    autoRotate,
    dispatch,
    fullscreen,
    handleAutoRotate,
    handleFullscreen,
    handleInverseDirection,
    handleMute,
    handleUse360,
    inverseDirection,
    devices,
    deviceId,
    muted,
    status,
    type,
    use360,
    misc = [],
  } = props

  const handleChange = value => {
    dispatch( { type: "DEVICE_ID", value } )
  }

  const fullscreenEnabled = document.fullscreenEnabled 

  return (
    <div className="MediaController">
      <Tooltip title="音声ミュート">
        <Button style={ styleBtn } type="text" onClick={ () => handleMute( !muted ) }>
        { muted ? 
          <IoMdVolumeOff /> : 
          <IoMdVolumeHigh /> 
        }
        </Button>
      </Tooltip>
      { ( type === 'camera' && devices.length > 0 ) ? (
        <div style={{ marginLeft: "3px", width: "30%" }}>
          <Tooltip title="カメラ選択">
            <FaCamera />&nbsp;
          </Tooltip>
          <Select 
            bordered="false"
            size="small" 
            style={{ width: "calc( 100% - 2em )" }} 
            onChange={ handleChange }
            value={ deviceId }
          >
          { devices.map( ( item, idx ) => (
            <Option key={idx} value={ item.deviceId }>{item.label}</Option>
          ))}
          </Select>
        </div>
      ):(
        <div>{status}</div>
      )}
      <div className="switches">
        { !misc.includes('4kvr360_235_dome_mode') && (
          <div>
            <Tooltip title="ビューワー自動回転">
              auto rotate&nbsp;
            </Tooltip>
            <Switch checked={autoRotate} onChange={ handleAutoRotate } size="small" />
          </div>
        )}
        <div>
          <Tooltip title="360度ビューワー on/off">
            <Md360 />&nbsp;
          </Tooltip>
          <Switch checked={use360} onChange={ handleUse360 } size="small" />
        </div>
      </div>
      <div className="right-buttons">
        <div>
          <Tooltip title="視点移動方向の変更">
            <Button style={ styleBtn } type="text" onClick={ () => handleInverseDirection( !inverseDirection )}>
            { inverseDirection ? 
              <CgArrowLeftR /> :
              <CgArrowRightR /> 
            }
            </Button>
          </Tooltip>
          { fullscreenEnabled && (
            <Tooltip title="フルスクリーン">
              <Button style={ styleBtn } type="text" onClick={ () => handleFullscreen( !fullscreen )}>
              { fullscreen ? 
                <RiFullscreenExitLine /> :
                <RiFullscreenLine /> 
              }
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  )
}