/**
 * media-viewer の state に関する js
 * useReduce 用
 */

export const STATUS = {
  IDLE: 'IDLE',
  INIT: 'INIT',
  RUNNING: 'RUNNING'
}

export const initialState = {
  autoRotate:       false, // when `true`, 360 viewer will rotate automatically.
  devices:          [],    // camera devices
  deviceId:         '',   // selected deviceId
  fullscreen:       false, // when set `true`, going to fullscreen mode
  inverseDirection: false, // when set `true`, rotate direction is inversed.
  loading:          false, // when `true`, loading icon will be displayed
  muted:            false,    // when `true`, audio of video will be muted
  status:           STATUS.IDLE, // indicating status of media-viewer
  use360:           true,   // when `true`, 360 viewer will be appeared, otherwise, original media will be displayed
  duration:         -1,
  currentTime:      -1,
}

export const reducer = ( state, action ) => {
  switch( action.type ) {
  case 'AUTO_ROTATE':
    return Object.assign( {}, state, { autoRotate: action.value })
  case 'DEVICES':
    return Object.assign( {}, state, { devices: action.value })
  case 'DEVICE_ID':
    return Object.assign( {}, state, { deviceId: action.value })
  case 'FULLSCREEN':
    return Object.assign( {}, state, { fullscreen: action.value })
  case 'INVERSE_DIRECTION':
    return Object.assign( {}, state, { inverseDirection: action.value })
  case 'LOADING':
    return Object.assign( {}, state, { loading: action.value })
  case 'MUTED':
    return Object.assign( {}, state, { muted: action.value })
  case 'STATUS':
    return Object.assign( {}, state, { status: action.value })
  case 'USE360':
    return Object.assign( {}, state, { use360: action.value })
  case 'SET_DURATION':
    return Object.assign( {}, state, { duration: action.value })
  case 'SET_CURRENT_TIME':
    return Object.assign( {}, state, { currentTime: action.value })
  default:
    return state
  }
}

