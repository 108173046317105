import React from 'react'

export default function Footer() {
  return (
    <div className="Footer">
      <div className="container">
        <div className="wrapper">
          <div>&copy; NTT Communications Corporation.</div>
        </div>
      </div>
    </div>
  )
}