import { v4 as uuidv4 } from 'uuid'
import { entrypoint } from '../config'

/**
 * 利用できるカメラデバイスを取得する 
 * 
 * @returns {Promise<Array<Object>>}
 */
export const getVideoDevices = async () => {
  if ( !navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices ) {
    throw new Error("this browser does not support enumerateDevices()")
  }

  return navigator.mediaDevices.enumerateDevices()
    .then( items => items.filter( item => item.kind === "videoinput" ))
    .catch( err => { throw err })
}

/**
 * isSupportedImmersiveVR
 * 
 * WebXR の immersive-vr が利用可能かを判定する
 * 
 * @returns {Promise<Boolean>}
 * 
 */
export const isSuppotedImmersiveVR = async () => {
  if ( !navigator.xr ) return false
  
  return await navigator.xr.isSessionSupported('immersive-vr' )
}

export const formattedTime = sec => {
  const mm = Math.floor( sec / 60 )
  const dd = ( '00' + ( sec % 60 ) ).slice(-2)

  return `${mm}:${dd}`
}

export const getClientId = () => {
  const key = 'stats-clientId'
  if( localStorage.getItem( key ) ) {
    return localStorage.getItem( key )
  } else {
    const clientId = _getUuid()
    localStorage.setItem( key, clientId )
    return clientId
  }
}

export const getSessionId = () => {
  return _getUuid()
}

export const postStatus = async ({ programId, clientId, sessionId, status } ) => {
  if( !programId || !clientId || !sessionId || !status ) {
    console.warn('postStatus - programId, clientId, sessionId and status MUST be specified')
    return
  }

  const url = `${entrypoint}/status/${programId}`
  const data = { id: programId, clientId, sessionId, status }

  return await fetch( url, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify( data )
  }).catch( err => {
    console.warn( 'postStatus error:%o', err )
  })
}

export const postMetrics = async ( metrics ) => {
  const url = `${entrypoint}/metrics/${metrics.id}`

  return await fetch( url, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify( metrics )
  }).catch( err => {
    console.warn( 'postMetrics error:%o', err )
  })
}

const _getUuid = () => {
  return uuidv4()
}