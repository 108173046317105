import Header from './page/header'
import Main from './page/main'
import Footer from './page/footer'

import './App.css';

function App() {
  return (
    <div className="App">
      <Header />
      <div className="container">
        <Main />
      </div>
      <Footer />
    </div>
  );
}

export default App;
