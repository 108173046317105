import React, { useReducer, useEffect, useState, useRef, useCallback } from 'react'
import { Alert, Button, Card, Col, Row, Slider, Space, Typography } from 'antd'
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai'
import gsap from 'gsap'

import { VRButton } from 'three/examples/jsm/webxr/VRButton'

import {
  FaPlay,
  FaPause,
  FaRegFileImage,
  FaRegFileVideo,
  FaCamera
} from 'react-icons/fa'

import {
  MdLiveTv
} from 'react-icons/md'

import MediaController from './media-controller'

import MsClient from '../libs/ms-client'
// import { ILS } from '@m-pipe/ils-player-sdk'
import View360 from '../libs/view-360'

import {
  STATUS,
  initialState,
  reducer
} from './media-viewer-state'

import {
  getVideoDevices,
  isSuppotedImmersiveVR,
  formattedTime,
  // postStatus,
  // postMetrics
} from '../libs/util'

import logo from '../assets/smart-vlive-logo-without-tm.png'
//import dbLogo from '../assets/211015_docomobusiness_rgb_color.png'
import watermark from '../assets/docomobusiness_white.png'

import './media-viewer.css'


const { Paragraph } = Typography
const { Meta } = Card

export default function MediaViewer( props ) {
  const {
    id,         // resource id
    type,       // mandatory `image`, `video`, `hls`, `camera` or `ils`
    src,        // required when `image` or `video`
    name,       // optional. name of resource
    description, // optional. description of resource
    endpoint,   // required when `ils`
    liveId,     // required when `ils`
    reverse,
    tagId,
    rotate,
    domeCroppedArea,
    links,
    misc,
    sessionId,
    clientId
  } = props

  const [ state, dispatch ] = useReducer( reducer, initialState )

  const [ _errMessage, setErrMessage ] = useState('')
  const [ _playing, setPlaying ] = useState( false )
  const [ _mouseOver, setMouseOver ] = useState( false )
  const loaderElem = useRef( null )
  const _ils       = useRef( null )
  const stream     = useRef( null )
  const videoElem  = useRef( null )
  const viewerElem = useRef( null )
  const viewerWrapperElem = useRef( null )
  const loaderWrapperElem    = useRef(null)
  const viewerBtnWrapperElem = useRef( null )
  const _view      = useRef( null )
  const _VRButton  = useRef( null )
  const _timer     = useRef( null )
  const _retryCount = useRef( 0 )

  /////////////////////////////////////////////////
  // use effects
  /////////////////////////////////////////////////

  //
  // id に応じて、アドレスバーを書き換える side effect
  //
  useEffect( () => {
    const url = new URL(window.location.pathname, window.location.origin)
    url.searchParams.set('resourceId', id)
    if( !!tagId ) {
      url.searchParams.set('tagId', tagId)
    }
    if( rotate != null ) {
      url.searchParams.set('rotate', rotate)
    }
    if( domeCroppedArea != null ) {
      url.searchParams.set('4kvr360_235_dome_cropped_area', domeCroppedArea)
    }
    window.history.pushState( null, '', url.toString())
  }, [ id, clientId, sessionId, tagId, rotate, domeCroppedArea ])



  //
  // deviceId が変更された際に、 stream を切り替える side effect
  //
  useEffect( () => {
    ( async () => {
      if( stream.current && !!state.deviceId ) {
        const _stream = await navigator.mediaDevices.getUserMedia( {
          video: { deviceId: state.deviceId }, audio: true
        })
        stream.current.getTracks().forEach( t => {
          t.stop()
          stream.current.removeTrack(t)
        })

        const newTracks = _stream.getTracks()
        newTracks.forEach( t => stream.current.addTrack( t ) )
      }
    })()
  }, [ state.deviceId ])

  //
  // viewer上のボタン表示を制御する side effect
  //
  useEffect( () => {
    // type の validation
    if( !/^(ils|camera|image|video|hls)$/.test( type ) ) {
      viewerBtnWrapperElem.current.style.opacity = 1
      viewerBtnWrapperElem.current.style.visibility = 'collapse'
      console.warn( `type: ${type} is not supported` )
      return
    }

    // IDLE 時のみ表示する
    if( state.status === STATUS.IDLE ) {
      viewerBtnWrapperElem.current.style.visibility = 'visible'
    } else {
      gsap.fromTo(
        viewerBtnWrapperElem.current,
        { opacity: 1 },
        { opacity: 0,
          duration: 0.1,
          onComplete: () => {
            // flex-box のポジションがおかしくなったため、 `display: none;` は
            // 利用せず `visibility: collapse;` を用いた
            viewerBtnWrapperElem.current.style.visibility = 'collapse'
          }
        }
      )
    }
  }, [ type, state.status ])

  //
  // loader animation を制御する side effect
  //
  useEffect( () => {
    // type の validation
    if( !/^(ils|camera|image|video|hls)$/.test( type ) ) {
      loaderWrapperElem.current.style.visibility = 'collapse'
      console.warn( `type: ${type} is not supported` )
      return
    }

    // IDLE 時は、loader は表示しない
    if( state.status === STATUS.IDLE ) {
      loaderWrapperElem.current.style.visibility = 'collapse'
    } else {
      // IDLE status 以外のときは、 state.loading に応じて、
      // loader の表示・非表示を行う
      if( state.loading ) {
        gsap.fromTo(
          loaderElem.current,
          { opacity: 0 },
          { opacity: 1, duration: 0.5,
            onStart: () => {
              loaderWrapperElem.current.style.visibility = 'visible'
            }
          }
        )
      } else {
        gsap.to(
          loaderElem.current,
          { opacity:0,
            duration: 1,
            onComplete: () => {
              // flex-box のポジションがおかしくなったため、 `display: none;` は
              // 利用せず `visibility: collapse;` を用いた
              if( loaderWrapperElem.current ) {
                loaderWrapperElem.current.style.visibility = 'collapse'
              }
            }
          }
        )
      }
    }
  }, [ type, state ])

  const terminate = () => {
    if( _timer.current ) {
      clearInterval( _timer.current )
      _timer.current = null
    }

    if( _ils.current ) {
      _ils.current.disconnect()
      _ils.current = null
    }

    if( stream.current ) {
      stream.current.getTracks().forEach( track => track.stop() )
      stream.current = null
    }

    if( _view.current ) {
      _view.current.destroy()
      _view.current = null
    }
  }

  // コンポーネントアンマウント時の解放処理を制御する side effect
  //
  useEffect(() => {
    return () => {
      terminate()
    }
  }, [])

  // VRButton 表示を制御する side effect
  //
  useEffect(() => {
    ( async () => {
      const flag = await isSuppotedImmersiveVR()

      if( !flag || state.status !== STATUS.RUNNING ) return

      if( state.use360 ) {
        viewerWrapperElem.current.appendChild( _VRButton.current )
        _view.current.renderer.xr.enabled = true
      } else {
        viewerWrapperElem.current.removeChild( _VRButton.current )
      }
    })()
  }, [ state.status, state.use360 ])

  /////////////////////////////////////////////////
  // useCallbacks
  /////////////////////////////////////////////////

  /**
   * type に応じたタイトルを返す
   *
   * @param {string} type - `image`, `video`, `hls`, `camera` or `ils`
   * @param {string} name - name of title
   * @returns <JSXDOMElement>
   */
  const getTitle = ( type ) => {
    return (
      <>
      { (() => {
          switch( type ) {
          case 'image':
            return <FaRegFileImage />
          case 'video':
            return <FaRegFileVideo />
          case 'hls':
            return <MdLiveTv />
          case 'camera':
            return <FaCamera />
          case 'ils':
            return <img style={{ width: "45%"}} src={logo} alt="Smart vLive"></img>
          default:
            return <span></span>
          }
        })()
      }
      </>
    )
  }

  /**
   * メディアリソースの読み込みと 360 度ビューワー表示を制御する
   * callback. status が　IDLEで無いときは動かない
   *
   */
  const start = useCallback( async () => {
    try {
      if( state.status !== STATUS.IDLE ) return

      if( !/^(ils|camera|image|video|hls)$/.test( type ) ) {
        return
      }

// comment out for unlimited retry
//      if( _retryCount.current > 5 ) {
//        setErrMessage('We retried 5 times, but it has been failed.')
//        return
//      }

      // status を `INIT` に変更し、 loading 状態を
      // true にする
      dispatch({ type: 'STATUS', value: STATUS.INIT })

      const view = new View360({
        stageElem: viewerElem.current, // 360 度表示用
        videoElem: videoElem.current   // オリジナル表示用
      })

      // Hack - due to apple's autoplay policy
      // we need to call `play()` while in user-gesture
      // context. Do not use await, since this call will
      // be blocked unless next proper `play()` called.
      if( /^(ils|camera|video|hls)$/.test( type ))  {
        let timer
        videoElem.current.play()
          .then( () => {})
          .catch( err => console.warn(err.message))

        videoElem.current.onplay = () => {
          setPlaying(true)
        }

        videoElem.current.onpause = () => {
          setPlaying(false)
        }

        viewerWrapperElem.current.onmousemove = () => {
          setMouseOver( true )
          if( timer ) clearTimeout( timer )

          timer = setTimeout(() => {
            timer = null
            setMouseOver( false )
          }, 2000)
        }

        viewerWrapperElem.current.onmouseover = () => {
          setMouseOver(true)

          timer = setTimeout(() => {
            timer = null
            setMouseOver( false )
          }, 2000)
        }

        viewerWrapperElem.current.onmouseout = () => {
          setMouseOver(false)

          if( timer ) {
            clearTimeout( timer )
            timer = null
          }
        }
      }
      dispatch({ type: 'LOADING', value: true })

      if( type === "ils" ) {
        // type が `ils` のときは、ILSへの接続処理を行い、
        // 受信ストリームを取得する
        
        /*
        console.log("ilsClient.new");
        console.log(videoElem.current);
        const ilsClient = new ILS({
          liveId,
          wsEndpoint: endpoint,
          entryMediaElements: videoElem.current
        });
        await ilsClient.connect();
        console.log("ilsClient.connect");
        ilsClient.setMuted(true);
        await ilsClient.play();
        console.log("ilsClient.play");
        */

        _ils.current = new MsClient({ programId: id, clientId, sessionId })
        stream.current = await _ils.current.getLiveStream( endpoint, liveId )
          .catch( err => {
            console.warn( 'getLiveStream() error: %o', err )
            return null
          })

        if( !stream.current ) {
          terminate()
          dispatch({ type: 'STATUS', value: STATUS.INIT })
          setTimeout(() => {
            _retryCount.current++
            setErrMessage(`connection disconnected. retrying ... (${_retryCount.current})`)
            start()
//          }, Math.pow( 2, _retryCount.current ) * 1000 )
          // modify for unlimited retry
          }, Math.min( Math.pow( 2, _retryCount.current ), 30 ) * 1000 )
 
          return 
        }

        _retryCount.current = 0
        setErrMessage('')

        // await postStatus({ programId: id, clientId, sessionId, status: 'READY' })

        // _timer.current = setInterval( async () => {
        //   const metrics = await _ils.current.getMetrics()
        //   await postMetrics( metrics )
        // }, 15000)

        _ils.current.on('disconnected', () => {
          terminate()
          dispatch({ type: 'STATUS', value: STATUS.INIT })
          setTimeout(() => {
            _retryCount.current++
            setErrMessage(`connection disconnected. retrying ... (${_retryCount.current})`)
            start()
          }, Math.pow( 2, _retryCount.current ) * 1000 )
        })
      } else if( type === "camera" ) {
        // type が `camera` のときは、gum を用いカメラからの映像
        // ストリームを取得する
        stream.current = await navigator.mediaDevices.getUserMedia({ video: true, audio: false })

        const devices = await getVideoDevices()
        dispatch( { type: "DEVICES", value: devices })
      }

      // ビューワーに、リソースを設定する
      view.setResource({
        reverse: !!reverse,
        resourceType: type,
        resourceUrl: src,       // type = `image` or `video`
        resourceMisc: misc,
        stream: stream.current, // type = `ils` or `camera`
      })


      // ビューの状態変化に対するリスナーを定義する
      // 状態に応じ、 reducer を呼び、 UI を更新する
      view.on('muted',       value => dispatch({ type: "MUTED", value }))
      view.on('use360',      value => dispatch({ type: "USE360", value }))
      view.on('autoRotate',  value => dispatch({ type: "AUTO_ROTATE", value }))
      view.on('duration',    value => dispatch({ type: "SET_DURATION", value }))
      view.on('currentTime', value => dispatch({ type: "SET_CURRENT_TIME", value }))

      if( viewerWrapperElem.current ) {
        viewerWrapperElem.current.addEventListener('fullscreenchange', () => {
          const isFullscreen = !!document.fullscreenElement
          dispatch( { type: "FULLSCREEN", value: isFullscreen } )
        },false )
      }

      // ビューワーを開始する
      await view.start()

      // keyboardEvent のハンドラーセット
      window.addEventListener('keydown', e => {
          switch( e.key.toLowerCase() ) {
          case '[': {
            view.zoomBy( -1 )
            break
          }
          case ']': {
            view.zoomBy( 1 )
            break
          }
          case 'w': {
            view.rotateUp( -2 )
            break
          }
          case 's': {
            view.rotateUp( 2 )
            break
          }
          case 'a': {
            view.rotateLeft( -2 )
            break
          }
          case 'd': {
            view.rotateLeft( 2 )
            break
          }
          default: {
            break
          }
        }
      })

      // VRButton の DOM Element を生成する
      // 表示制御は、 useEffect() で、制御する
      _VRButton.current = VRButton.createButton( view.renderer )

      // ref に view インスタンスを格納する
      _view.current = view

      // status を `RUNNING` に遷移し、 loading を false
      // にする
      dispatch({ type: 'LOADING', value: false })
      dispatch({ type: 'STATUS', value: STATUS.RUNNING })

      if ( misc?.includes( 'disable_360' ) ) {
        _view.current.use360 = false
      }

    } catch(err) {
      console.warn( err )
    }
  }, [ type, src, endpoint, liveId, state.status, id, misc, clientId, sessionId, reverse ])

  /**
   * mute ボタンに対するハンドラ
   *
   */
  const handleMute = useCallback( value => {
    if( state.status !== STATUS.RUNNING ) return

    if( _view.current ) {
      _view.current.muted = value
    }
  }, [ state.status ])

  /**
   * 360度ビューワースイッチに対するハンドラ
   *
   */
  const handleUse360 = useCallback( value => {
    if( state.status !== STATUS.RUNNING ) return

    if( _view.current ) {
      _view.current.use360 = value
    }
  }, [ state.status ])

  /**
   * 自動回転スイッチに対するハンドラ
   *
   */
  const handleAutoRotate = useCallback( value => {
    if( state.status !== STATUS.RUNNING ) return

    if( _view.current ) {
      _view.current.autoRotate = value
    }
  }, [ state.status ])

  /**
   * 自動回転方向に関するハンドラ
   *
   */
  const handleInverseDirection = useCallback( value => {
    if( state.status !== STATUS.RUNNING ) return

    if( _view.current ) {
      _view.current.inverseDirection = value
      dispatch( { type: "INVERSE_DIRECTION", value } )
    }
  }, [ state.status ])

  /**
   * Fullscreen に関するハンドラ
   *
   */
  const handleFullscreen = useCallback( async value => {
    if(
      !document.fullscreenEnabled ||
      state.status !== STATUS.RUNNING
    ) return

    if( viewerWrapperElem.current ) {
      if( value ) {
        await viewerWrapperElem.current.requestFullscreen()
      } else {
        await document.exitFullscreen()
      }
    }
  }, [ state.status ])

  /////////////////////////////////////////////////
  // rendering
  /////////////////////////////////////////////////
  return (
    <div className="MediaViewer">
      <MediaController
        {...state}
        dispatch={ dispatch }
        handleAutoRotate={ handleAutoRotate }
        handleFullscreen={ handleFullscreen }
        handleInverseDirection={ handleInverseDirection }
        handleMute={ handleMute }
        handleUse360={ handleUse360 }
        type={ type }
        misc={ misc }
      />
      { _errMessage !== '' && (
        <Alert message={ _errMessage } type="error" showIcon />
      )}
      <Card
        style={{ background: '#c10026' }}
        cover={(
          <>
            <div className="viewer-wrapper" ref={ viewerWrapperElem }>
              <div className="viewer" ref={ viewerElem } style={{ transform: reverse ? "rotate(180deg)" : 'rotate(0deg)' }}>
                <video ref={ videoElem } playsInline></video>
              </div>
              {/*
              <div className='db-logo'>
                <img src={dbLogo} height={32} alt="db logo" />
              </div>
              <div className='svl-logo'>
                <img src={logo} height={32} alt="Smart vLive" />
              </div>
              */}
              <div className='watermark-logo'>
                <img src={watermark} height={32} alt="docomo business" />
              </div>
              <div className='cursor-none corner-tl'></div>
              <div className='cursor-none corner-tr'></div>
              <div className='cursor-none corner-bl'></div>
              <div className='cursor-none corner-br'></div>
              <div className="viewer-btn-wrapper" ref={ viewerBtnWrapperElem }>
                <Button style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "4em",
                  height: "4em"
                }} type="primary" shape="circle" danger onClick={() => start()}>
                  <FaPlay style={{ fontSize: "1.8em" }} />
                </Button>
              </div>
              <div 
                className='controller'
                style={{
                  visibility: ( state.status === STATUS.RUNNING && _mouseOver ) ? 'visible' : 'collapse'
                }}
              >
                <Button 
                  type="text"
                  shape="default"
                  onClick={ () => {
                    if( _playing ) {
                      if( _ils.current ) {
                        _ils.current.pause()
                          .then( () => videoElem.current.pause() )
                      } else {
                        videoElem.current.pause()
                      }
                    } else {
                      if( _ils.current ) {
                        _ils.current.resume()
                          .then( () => videoElem.current.play() )
                      } else {
                        videoElem.current.play()
                      }
                    }
                  }}
                >
                  { _playing ? (
                    <FaPause 
                      style={{
                        visibility: (state.status === STATUS.RUNNING && _mouseOver) ? 'visible' : 'collapse',
                        color: '#fff'
                      }}
                    />
                  ):(
                    <FaPlay 
                      style={{
                        visibility: (state.status === STATUS.RUNNING && _mouseOver) ? 'visible' : 'collapse',
                        color: '#fff'
                      }}
                    />
                  )}
                </Button>
              </div>
              <div className="loader-wrapper" ref={ loaderWrapperElem }>
                <div className="loader" ref={ loaderElem }></div>
              </div>
            </div>
          </>
        )}
      >
        {(
          ['video', 'hls'].includes(type) &&
          Number.isFinite( state.duration ) &&
          Number.isFinite( state.currentTime ) &&
          state.duration > 0 &&
          state.currentTime > 0
        ) && (
          <Slider 
            min={0} 
            max={ Math.ceil( state.duration ) } 
            value={ Math.ceil( state.currentTime )} 
            marks={{ [ Math.ceil( state.duration )]: formattedTime( Math.ceil( state.duration ) ) }}
            tooltip={{formatter: formattedTime}}
            onChange={ value => {
              dispatch({ type: "SET_CURRENT_TIME", value })
            }}
            onAfterChange={ value => {
              _view.current.currentTime = value
            }}
          />
        )}
        <Meta 
          style={{ color: '#fff', background: '#c10026'}} 
          title={ getTitle(type, name) } 
          description={<div style={{ color: "#fff", fontWeight: "bold" }}>{description}</div>} 
        />
        {(links && links.length !== 0) && (
          <div
            style={{ color: '#fff', background: '#c10026'}}
          >
            {links.map(link => <div><a href={link.url}>{link.text}</a></div>)}
          </div>
        )}
        <Paragraph style={{ color: '#fff', padding: '12px' }}>
          <Row gutter={16}>
            <Col span={20}>
              <span className='keyboard-shortcut-'>操作方法(キー操作)</span><br/>
              <strong className='keyboard-shortcut'>[</strong> ズームイン<br/>
              <strong className='keyboard-shortcut'>]</strong> ズームアウト<br/>
              <strong className='keyboard-shortcut'>w</strong> カメラを上方向に<br/>
              <strong className='keyboard-shortcut'>s</strong> カメラを下方向に<br/>
              <strong className='keyboard-shortcut'>a</strong> カメラを左方向に<br/>
              <strong className='keyboard-shortcut'>d</strong> カメラを右方向に
            </Col>
            <Col span={4}>
              <div className='zoom-buttons'>
                <Space direction='vertical'>
                  <Space>
                    <Button
                      onClick={() => { _view.current?.zoomBy( +1 ) }}
                      type="default"
                      icon={<AiOutlineMinus />}
                    />
                    <Button
                      onClick={() => { _view.current?.zoomBy( -1 ) }}
                      icon={<AiOutlinePlus />}
                      type="default"
                    />
                  </Space>
                  <div><strong>ズームイン<br/>ズームアウト<br/>制御ボタン</strong></div>
                </Space>
              </div>
            </Col>
          </Row>
        </Paragraph>
      </Card>
    </div>
  )
}
