import * as THREE from 'three'
import { EventEmitter } from 'events'
import { OrbitControls } from './OrbitControls' // 'three/examples/jsm/controls/OrbitControls'
import HLS from 'hls.js';

const MIN_FOV  = 30
const INIT_FOV = 60
const MAX_FOV  = 75


export default class View360 extends EventEmitter {
  camera = null
  controls = null
  height = 0
  imageElem = null
  resourceType = "image" // "image" or "video"
  resourceUrl = ""
  scene = null
  stage = null
  stream = null
  videoElem = null
  width = 0
  _inverseDirection = false
  _renderer = null
  _rotateSpeed = 0.2
  _use360 = true
  timer = null

  _fovDelta = null
  _fovTimer = null

  _angleTimer = null

  _hls = null

  _startTimeForInfinity = null

  /**
   * constructor
   * 
   * @param {object} props 
   * @param {HTMLElement} props.stageElem - ビューワーを表示する DOM Element
   * @param {HTMLVideoElement} props.videoElem - オリジナル映像を表示する DOM Element
   */
  constructor( props ) {
    super(props)
    const { stageElem, videoElem } = props

    this.stage = stageElem
    this.videoElem = videoElem
  }

  /**
   * destroy()
   * 
   * 終了処理
   * 
   */
  destroy() {
    this._renderer.setAnimationLoop( null )

    if( this.timer ) {
      clearInterval( this.timer )
      this.timer = null
    }

    if( this._angleTimer ) {
      clearInterval( this._angleTimer )
      this._angleTimer = null
    }

    if( this.videoElem ) {
      this.videoElem.pause()
      this.videoElem.src = null
    }

    if( this.stage ) {
      this.stage.innerHTML = ''
    }
    this.scene = null

    this._hls?.destroy()
    this._hls = null
  }

  /**
   * setResource()
   * 
   * 表示するメディアリソースを設定する
   * 
   * @param {object} param 
   * @param {string} param.resourceType
   * @param {string} param.resourceUrl - resourceType が `image` の時に指定
   * @param {MediaStream} stream - resourceType が `imaga` 以外の時に指定
   * 
   * @public
   * 
   */
  setResource( { resourceType, resourceUrl, resourceMisc, stream, reverse }  ){
    this.resourceType = resourceType
    this.resourceUrl = resourceUrl
    this.resourceMisc = resourceMisc || []
    this.reverse = reverse
    this.stream = stream
    this.width = !!this.stage.clientWidth ? this.stage.clientWidth : 720
    this.height = !!this.stage.clientHeight ? this.stage.clientHeight: 480
  }

  /**
   * start()
   * 
   * ビューワーを開始する
   * 
   * @returns {Promise<>}
   * @public
   * 
   */
  start = async () => {
    if( !this.stage || !this.resourceType || !this.width || !this.height ) {
      throw new Error("You need to call `setResource()` before `start()`")
    }

    await this._init()
    this._setOrbitControls()
    this._render()
  }

  /**
   * getter - renderer
   * 
   */
  get renderer() {
    return this._renderer
  }

  /**
   * Setter - mute
   * 
   * @param {boolean} value
   * 
   */
  set muted( value ) {
    if( this.videoElem ) {
      this.videoElem.muted = !!value
      this.emit( 'muted', this.videoElem.muted )
    }
  }

  /**
   * Getter - mute
   * 
   * @returns {boolean}
   * 
   */
  get muted() {
    return this.videoElem ? this.videoElem.muted : true
  }

  /**
   * Setter - 360度ビューワー表示
   * 
   * @param {boolean} value
   * 
   */
  set use360( value ){
    this._use360 = !!value

    if( this._use360 ) {
      if( this.imageElem ) {
        this.imageElem.style.visibility = "hidden"
      } else if( this.videoElem ) {
        this.videoElem.style.visibility = "hidden"
      }
      this._renderer.domElement.style.visibility = "visible"
    } else {
      if( this.imageElem )  {
        this.imageElem.style.visibility = "visible"
      } else if( this.videoElem ) {
        this.videoElem.style.visibility = "visible"
      }
      this._renderer.domElement.style.visibility = "hidden"
    }
    this.emit( 'use360', this._use360 )
  }

  /**
   * Getter - 360度ビューワー表示
   * 
   * @returns {boolean}
   * 
   */
  get use360() {
    return this._use360
  }

  /**
   * Setter - 自動回転
   * 
   * @param {boolean} value
   * 
   */
  set autoRotate( value ) {
    if( this.controls ) {
      this.controls.autoRotate = value

      this.emit( 'autoRotate', this.controls.autoRotate )
    }
  }

  /**
   * Getter - 自動回転
   * 
   * @returns {boolean}
   * 
   */
  get autoRotate() {
    return this.controls.autoRotate
  }

  /**
   * setter - 回転方向
   * 
   * @param {boolean} value
   * 
   */
  set inverseDirection( value ) {
    this._inverseDirection = value
    this._setRotateSpeed()
  }

  /**
   * gette - 回転方向
   * 
   * @returns {boolean}
   * 
   */
  get inverseDirection() {
    return this._inverseDirection
  }

  get currentTime() {
    return this.videoElem.currentTime
  }
  set currentTime( val ) {
    this.videoElem.currentTime = val
  }

  get fov() {
    if( this.camera && this.camera.fov ) {
      return this.camera.fov
    } else {
      return null
    }
  }

  set fov( val ) {
    if( val < MIN_FOV || val > MAX_FOV ) return

    if( this.camera && this.camera.fov ) {
      this.camera.fov = val
      this.camera.updateProjectionMatrix();
    }
  }

  reflectFov() {
    const delta = this._fovDelta - this.camera.fov
    if (Math.abs(delta) < 0.2) {
      this.camera.fov = this._fovDelta
      this._fovDelta = null
      this._fovTimer = null
    } else {
      this.camera.fov += (delta * 0.5)
      this._fovTimer = setTimeout(this.reflectFov.bind(this), 30)
    }
    this.camera.updateProjectionMatrix();
  }

  zoomBy = val => {
    if( this.camera && this.camera.fov ) {
      const base = this._fovDelta === null ? this.camera.fov : this._fovDelta
      this._fovDelta = Math.min(Math.max(base + val, MIN_FOV), MAX_FOV)
      if (this._fovTimer === null) {
        this.reflectFov()
      }
    }
  }

  get angle() {
    if( this.controls && this.camera ) {
      return {
        azimuthal: this.controls.getAzimuthalAngle(),
        polar: this.controls.getPolarAngle()
      }
    } else {
      return null
    }
  }

  rotateLeft = val => {
    this.controls.rotateLeft( Math.PI * val / 180 )
  }

  rotateUp = val => {
    this.controls.rotateUp( Math.PI * val / 180 )
  }


  //////////////////////////////////////////////////////////
  // private methods
  //////////////////////////////////////////////////////////

  /**
   * 初期化処理
   * 
   * @private
   * 
   */
  _init = async () => {
    // シーン、カメラ、レンダラー生成
    this.scene = new THREE.Scene()

    // OrbitControlsを使用すると、defaultではカメラはワールド座標の原点(0,0,0)を見続ける
    // カメラ位置を少し後方に移動させ、原点周りを移動させることで、球体との距離を一定に保たせる
    const aspect = this.width / this.height
    this.camera = new THREE.PerspectiveCamera( INIT_FOV, aspect, 1, 1000 )
    this.camera.position.set( 0, 0, 0.000001 )

    this.scene.add( this.camera )

    // 球体の形状を生成
    const sphereSize = 5
    const geometry = new THREE.SphereGeometry( sphereSize, 256, 90 )
    geometry.scale( -1, 1, 1 )

    // Three.jsをはじめ一般的な3Dエンジンは、球体へのテクスチャとして
    // 正距円筒図法(equirectangular)での描画を前提としたUVマッピングが設定されている。
    // しかし、4KVR360のDOMEモード(235度)で撮影した場合、魚眼レンズ(fisheye lens)の映像を使用することになる。
    // 射影方式は等距離射影方式(Equidistance Projection)。
    // この映像をジオメトリに貼り付けるため、4KVR360に特化した方法でUVマッピングを行わなければならない。
    // ここでは、半径1のジオメトリの各頂点の座標(x,y,z)とテクスチャ上の座標(u,v)の対応関係を算出し配列に保持し、
    // 元のジオメトリにUVマッピングのデータとして設定し直している。
    // cf.
    // https://qiita.com/mechamogera/items/b6eb59912748bbbd7e5d
    // https://stackoverflow.com/questions/55486346/how-to-render-360-x-235-degree-fov-image-with-threejs
    // 
    // 4KVR360のDOMEモード(235度)で配信を行うと、
    // アスペクト比16:9の画角の真ん中に魚眼レンズの映像が配置する映像になる。
    // そのままの映像では不要な部分まで配信して帯域を浪費するため、
    // LiveShellの機能で正方形にクロップして配信を行っている。
    // クロップを忘れると映像が縦に伸びてしまうため注意。
    if (this.resourceMisc.includes('4kvr360_235_dome_mode')) {
      // 4KVR360のDOMEモード(235度)向けの処理では正方形にクロップされた映像を前提としているが、
      // ここでは帯域が厳しい環境(例:車載等でSIMで配信)向けに更にクロップして配信される場合向けの処理を実装している。
      // 当if文内の以下の変数等がそれにあたる。
      // - `4kvr360_235_dome_cropped_area`
      // - `domeCroppedArea`
      // - `croppedAreas`

      // configのmiscより `4kvr360_235_dome_cropped_area=` で始まる値を探し出し、
      // `4kvr360_235_dome_cropped_area=` を除去して代入
      // 見つからなかった場合は `undefined`
      let domeCroppedArea = this.resourceMisc.find(value => {
        return typeof value === 'string' && value.startsWith('4kvr360_235_dome_cropped_area=')
      })?.replace(/^4kvr360_235_dome_cropped_area=/, '')

      // url parameterに `4kvr360_235_dome_cropped_area` の指定があった場合は上書き
      {
        const params = new URL(window.location).searchParams
        if (params.has('4kvr360_235_dome_cropped_area')) {
          domeCroppedArea = params.get('4kvr360_235_dome_cropped_area')
        }
      }

      const croppedAreas = {
        x: 1.0,
        y: 1.0,
      };
      if (domeCroppedArea) {
        // domeCroppedAreaのフォーマットをチェック
        // url parameterに `&domeCroppedArea=` のように値に空文字を指定するとconfigによる指定をリセット可能
        // domeCroppedAreaは `x0.5,y1.0` のように `,` 区切りで複数を指定可能
        if (/^([xy][0-9.]+(,[xy][0-9.]+)*)?$/.test(domeCroppedArea)) {
          const domeCroppedAreaArray = domeCroppedArea.split(',')
          // 指定された値で上書き
          domeCroppedAreaArray.forEach(croppedArea => {
            // Safariが正規表現のlookbehind assertion(後読みアサーション)に対応していないため愚直に処理
            // const [axis, cropped] = croppedArea.split(/(?<=[x-z])(?=[0-9.])/)
            const axis = croppedArea.match(/^[xyz]/)[0];
            const cropped = croppedArea.match(/[0-9.]+$/)[0];
            croppedAreas[axis] = Number(cropped);
          })
        }
      }

      const imgWidth = 0.8; // I thought this should be 235/360, but used trial and error for less fisheye distortion
      const positions = geometry.attributes.position;
      const uvs = [];
//      const uvs = geometry.attributes.uv;

      for (let i = 0; i < positions.count; i++) {
        const from = i * positions.itemSize;
        const to = from + positions.itemSize;
        const xyz = positions.array.slice(from, to);
        const [positionX, positionY, positionZ] = xyz.map(value => value / sphereSize);
        //y is top to bottom
        //z is in-out?
        //x is side-side? positive x is front, negative is back

        const yaw = Math.atan2(positionZ, positionX) / (Math.PI); // around, -1 to 1
        let pitch = Math.acos(positionY) / Math.PI; // height, -0.5 to 0.5
        pitch *= imgWidth;

        if(pitch < 0.5){
          // how to do fisheye correction??
          // var correction = (fvNj.x == 0 && fvNj.z == 0) ? 1 : (Math.acos(fvNj.y) / Math.sqrt(fvNj.x * fvNj.x + fvNj.z * fvNj.z)) * (2 / Math.PI);
          const x = Math.cos(yaw * Math.PI); // -1 to 1
          const y = Math.sin(yaw * Math.PI); // -1 to 1
          const xx = x * pitch / croppedAreas.x;
          const yy = y * pitch / croppedAreas.y;
          if (Math.abs(xx) > 0.5 || Math.abs(yy) > 0.5) {
            uvs.push(0, 0);
//            uvs.array[i * uvs.itemSize + 0] = 0;
//            uvs.array[i * uvs.itemSize + 1] = 0;
          } else {
            const u = 0.5 - yy;
            const v = 0.5 + xx;
            uvs.push(u, v);
//            uvs.array[i * uvs.itemSize + 0] = u;
//            uvs.array[i * uvs.itemSize + 1] = v;
          }
        }else{
          uvs.push(0, 0);
//          uvs.array[i * uvs.itemSize + 0] = 0;
//          uvs.array[i * uvs.itemSize + 1] = 0;
        }
      }

      geometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(uvs), 2));
    }

    // テクスチャ画像を読み込む
    const texture = this.resourceType === "image" ?
      await this._getImageTexture() :
      await this._getVideoTexture()

    // マテリアルを生成
    const material = new THREE.MeshBasicMaterial({
      map: texture
    })

    // 球体を生成
    const sphere = new THREE.Mesh( geometry, material )
    if (this.resourceMisc.includes('4kvr360_235_dome_mode')) {
      sphere.rotateZ(+Math.PI / 2)
      sphere.rotateX(-Math.PI / 2)
    } else {
      sphere.rotateY(-Math.PI / 2)
    }

    // configのmiscより `rotate=` で始まる値を探し出し、 `rotate=` を除去して代入
    // 見つからなかった場合は `undefined`
    let rotateValues = this.resourceMisc.find(value => {
      return typeof value === 'string' && value.startsWith('rotate=')
    })?.replace(/^rotate=/, '')

    // url parameterに `rotate` の指定があった場合は上書き
    {
      const params = new URL(window.location).searchParams
      if (params.has('rotate')) {
        rotateValues = params.get('rotate')
      }
    }

    if (rotateValues) {
      // rotateのフォーマットをチェック
      // url parameterに `&rotate=` のように値に空文字を指定するとconfigによる指定をリセット可能
      // rotateは `x90,y45` のように `,` 区切りで複数を指定可能
      if (/^([xyz][0-9]+(,[xyz][0-9]+)*)?$/.test(rotateValues)) {
        const rotateArray = rotateValues.split(',')
        // 指定された順に指定された軸で指定された角度でsphereを回転
        rotateArray.forEach(rotate => {
          // Safariが正規表現のlookbehind assertion(後読みアサーション)に対応していないため愚直に処理
          // const [axis, angle] = rotate.split(/(?<=[x-z])(?=[0-9])/)
          const axis = rotate.match(/^[xyz]/)[0];
          const angle = rotate.match(/[0-9]+$/)[0];
          sphere[`rotate${axis.toUpperCase()}`](angle / 360 * Math.PI * 2)
        })
      }
    }

    // 全天球のsphereの回転角度を出力
    {
      const values = ['x', 'y', 'z'].reduce((table, key) => {
        const value = sphere.rotation[key]
        table[key] = {
          'radian(value)': Math.round(value * 1000) / 1000,
          'radian(coefficient)': Math.round(value / Math.PI * 1000) / 1000,
          'degree': Math.round(value / Math.PI * 180 * 1000) / 1000,
        }
        return table
      }, {})
      console.log('omnidirectional sphere')
      console.table(values)
    }

    this.scene.add( sphere )

    if (this.resourceMisc.includes('4kvr360_235_dome_mode')) {
      // 魚眼形状の映像の縁の荒れた部分を隠すRingGeometry
      // 映像の周囲の黒い部分は実際には概ねRGB(14,14,14)のため同じ色を指定している
      const geometry = new THREE.RingGeometry(3, 5, 90);
      geometry.translate(0, 0, 1.87);
      const material = new THREE.MeshBasicMaterial({color: 0x0e0e0e, side: THREE.DoubleSide});
      const mesh = new THREE.Mesh(geometry, material);
      this.scene.add(mesh);
    }

    this._renderer = new THREE.WebGLRenderer( { antialias: true } )
    this._renderer.setSize( this.width, this.height )
    this._renderer.setClearColor( { color: 0x000000 })
    this._renderer.setPixelRatio( window.devicePixelRatio )

    // canvas elem　を追加する
    this.stage.appendChild( this._renderer.domElement )
    this._renderer.render( this.scene, this.camera )

    this._renderer.domElement.style.cursor = 'grab'

    // window size が変更された際に、各オブジェクトがcanvas などの
    // サイズに fit するように変更する
    window.onresize = () => {
      // ベースとなる表示element のサイズ取得
      this.width = this.stage.clientWidth
      this.height = this.stage.clientHeight

      // カメラとレンダラーを resize する
      this.camera.aspect = this.width / this.height
      this.camera.updateProjectionMatrix()
      this._renderer.setSize( this.width, this.height )
      
      // オリジナル表示用のビデオ要素のサイズ変更
      if( this.videoElem ) {
        this.videoElem.style.width = this.width + "px"
        this.videoElem.style.height = this.height + "px"
      }

      // オリジナル表示用の画像要素のサイズ変更
      if( this.imageElem ) {
        this.imageElem.style.width = this.width + "px"
        this.imageElem.style.height = this.height + "px"
      }
    }
  }

  /**
   * Video 要素のプロパティ設定
   * 
   * @private
   */
  _setupVideoElement() {
    if( !this.videoElem ) throw new Error('You have to set videoElem')

    this.videoElem.crossOrigin = "anonymous";
    this.videoElem.loop = true
    this.videoElem.muted = false
  }

  /**
   * 再生開始時間の算出・設定
   * 
   * SafariではliveのHLSはvideo.durationがInfinityを返す。
   * video.durationがInfinityを返すとseekbarを生成できない。
   * 映像の開始時間がわかれば、現在時間の差分をdurationとして使用できる。
   * リソースのURLから開始時間を取得できることがあるのでそれを利用する。
   * URLから開始時間が取得できなかった場合は、仕方がないので動画再生時間を開始時間とする。
   * 
   * @private
   */
  _calculateStartTimeForInfinity() {
    // そもそもresourceUrlがURLでない場合(無指定等)、現在時間を用いる。
    try {
      new URL(this.resourceUrl)
    } catch (error) {
      console.log('resourceUrl: invalid');
      this._startTimeForInfinity = new Date().getTime()
      return
    }

    const url = new URL(this.resourceUrl)

    // URLから開始時間を抽出。
    // URLのフォーマットは以下のリンク先のフォーマットを想定。
    // https://docs.aws.amazon.com/ja_jp/mediapackage/latest/ug/time-shifted.html
    // ex.
    //   /out/v1/997cbb27697d4863bb65488133bff26f/sports.mpd?start=1513717228&end=1513720828
    //   /out/v1/997cbb27697d4863bb65488133bff26f/start/2017-12-19T13:00:28-08:00/end/2017-12-19T14:00:28-08:00/sports.mpd
    let start = null
    const param = url.searchParams.get('start')
    const match = url.pathname.match(/\/(start)\/([^/]+)\//)
    if (param !== null) {
      start = param
    } else if (match !== null) {
      start = match[2]
    }
    console.log(`extract start time from resource url: ${start}`);

    // 抽出できた開始時間がない場合、現在時間を用いる。
    // 抽出した開始時間のフォーマットを調べ、フォーマット毎に日付型に変換。
    let dtm = null
    if (start === null) {
      dtm = new Date()
    } else if (/^[0-9.]+$/.test(start)) {
      dtm = new Date(Number(start) * 1000)
    } else {
      dtm = new Date(start)
    }
    console.log(`calculate start time: ${dtm.toISOString()}`);

    this._startTimeForInfinity = dtm.getTime()
  }

  /**
   * 画像のテクスチャ取得。Promise で返す
   * 
   * @returns {Promise<THREE.Texture>}
   * @private
   * 
   */
  _getImageTexture(){
    return new Promise( ( resolve, reject ) => {
      // image要素に画像ファイルを読み込み、テクスチャとして取り出す
      this.imageElem = new window.Image()

      this.imageElem.onload = () => {
        const texture = new THREE.Texture( this.imageElem )
        texture.needsUpdate = true

        // オリジナル画像表示用に image 要素を追加する
        this.imageElem.style.width = this.width + "px"
        this.imageElem.style.height = this.height + "px"
        this.imageElem.style.visibility = "hidden"
        this.stage.appendChild( this.imageElem )


        resolve( texture )
      }
      this.imageElem.onerror = err => {
        reject( err )
      }

      this.imageElem.crossOrigin = "Anonymous"
      this.imageElem.src = this.resourceUrl
    })
  }

  /**
   * 映像のテクスチャ取得
   * このライブラリでは、 video 要素についてはすでに HTML に埋め込み済みのものを
   * 用いる方式となっているため、 Image 要素とはことなり `appendChild()` は
   * 行わない
   * texture は　Promise で返す
   * 
   * @returns {Promise<THREE.Texture>}
   * @private
   */
  _getVideoTexture() {
    return new Promise( (resolve, reject ) => {
      this._setupVideoElement( false )

      // 将来HLSに対応する環境が増えた時のために、
      // `HTMLMediaElement.canPlayType` -> `HLS.isSupported`
      // の順で処理をすべき。
      // しかし、Android Chromeは
      // `application/vnd.apple.mpegurl` に
      // 対応していると判定されるにもかかわらず
      // ライブ配信を再生すると様々な問題が発生する。
      // - 代表的な問題
      //   - ライブ配信の形式次第で `seek` 不可
      //   - ライブ配信の形式次第で `currentTime` の変更不可
      //   - `duration` が更新されない
      //   - `duration` が更新されないため `currentTime` を直近の時間に指定できない
      //   - `seekable` の値がおかしい
      //   - `buffered` が取得できない
      // ver.102 (Redme Note 9S) で上記問題を確認。
      // `HLS.js` はAndroid Chromeをサポートしているため、
      // 判定順を入れ替え `HLS.js` を使用するようにしている。
      let useHlsJs = false;
      if( this.stream ) {
        this.videoElem.srcObject = this.stream
      } else if ( this.resourceType === 'hls' ) {
        // iPad及びmacOS Safariは直接HLSを再生できMedia Source Extensions(MSE)にも対応しているため、
        // `isSupported` でも `canPlayType` でも `true` を返す。
        // ところが `MSE` で再生しているvideo要素はcanvasにdrawImageしても何も表示されない模様(詳細は未調査)。
        // このため、iPad及びmacOS Safariでは `HLS.js` を使用しないようにさせている。
        const ua = window.navigator.userAgent.toLowerCase();
        const isIPad = ua.includes('ipad') || (ua.includes('macintosh') && 'ontouchend' in document)
        const isSafari = ua.includes('safari') && !ua.includes('chrome')
        if ( HLS.isSupported() && !isIPad && !isSafari) {
          useHlsJs = true;
          this._hls?.detachMedia()
          this._hls?.destroy()
          this._hls = new HLS()
          this._hls.loadSource(this.resourceUrl)
          this._hls.attachMedia(this.videoElem)
        } else if ( this.videoElem.canPlayType( 'application/vnd.apple.mpegurl' ) ) {
          this.videoElem.src = this.resourceUrl
        } else {
          console.log(`can not play HLS`)
        }
        console.log(`use hls.js: ${useHlsJs}`)
      } else {
        this.videoElem.src = this.resourceUrl
      }
      this.videoElem.style.width = this.width + "px"
      this.videoElem.style.height = this.height + "px"
      this.videoElem.style.visibility = "hidden"

      const onload = () => {
        this.videoElem.play()
          .then( () => {
            // 再生が成功したタイミングで、テクスチャを生成し、 resolve する
            const texture = new THREE.VideoTexture( this.videoElem )
            texture.minFilter = THREE.LinearFilter
            texture.magFilter = THREE.LinearFilter
            texture.format = THREE.RGBFormat
            this._calculateStartTimeForInfinity();
            this.timer = setInterval( () => {
              const currentTime = this.videoElem.currentTime
              const duration = Number.isFinite( this.videoElem.duration ) ?
                this.videoElem.duration :
                ( Date.now() - this._startTimeForInfinity ) / 1000
              this.emit( 'currentTime', currentTime )
              this.emit( 'duration', duration )
            }, 1000 )
            resolve( texture )
          })
          .catch( err => {
            reject( err )
          })
      }

      if (useHlsJs) {
        // 読み込みが完了したら、再生を開始する
        this._hls.on( HLS.Events.MEDIA_ATTACHED, onload )
        this._hls.on( HLS.Events.ERROR, err => {
          reject(err)
        } )
      } else {
        // 読み込みが完了したら、再生を開始する
        this.videoElem.addEventListener( "loadedmetadata", onload , false )
        this.videoElem.addEventListener( "error", err => {
          reject(err)
        }, false )
      }
    })
  }

  /**
   * 視点移動用の Three.js utility, OrbitControls のプロパティを設定する
   * 
   * @private
   * 
   */
  _setOrbitControls() {
    this.controls = new OrbitControls( this.camera, this._renderer.domElement )

    // ref. https://threejs.org/docs/#examples/en/controls/OrbitControls
    this.controls.enableDamping = true
    this.controls.enableRotate = true
    this.controls.autoRotate = false
    this.controls.autoRotateSpeed = 1.0
    this.controls.dampingFactor = 0.2
    // this.controls.rotateSpeed = 0.2
    this.controls.enableZoom = false
    this.controls.enablePan = false
    // this.controls.minDistance = 0.25
    // this.controls.maxDistance = 2

    this.controls.keys = {
      LEFT: 'KeyA',
      RIGHT: 'KeyD',
      UP: 'KeyW',
      BOTTOM: 'KeyS',
    }

    this._setRotateSpeed()

    if (this.resourceMisc.includes('4kvr360_235_dome_mode')) {
      this._angleTimer = setInterval(() => {
        if( this.camera && this.camera.fov ) {
          // 許可する回転角度の最大値
          const allowedAngle = 111

          // 画面上の9点のベクトルを生成
          // left,center,right x bottom,middle,top
          const lbVector3 = new THREE.Vector3(-1, -1, +10)
          const lmVector3 = new THREE.Vector3(-1,  0, +10)
          const ltVector3 = new THREE.Vector3(-1, +1, +10)
          const cbVector3 = new THREE.Vector3( 0, -1, +10)
//          const cmVector3 = new THREE.Vector3( 0,  0, +10)
          const ctVector3 = new THREE.Vector3( 0, +1, +10)
          const rbVector3 = new THREE.Vector3(+1, -1, +10)
          const rmVector3 = new THREE.Vector3(+1,  0, +10)
          const rtVector3 = new THREE.Vector3(+1, +1, +10)

          // ワールド座標正面のベクトル(unproject対象外)
          const frontVector3 = new THREE.Vector3( 0,  0, +10)

          // カメラ視点からのベクトルに変換
          lbVector3.unproject(this.camera)
          lmVector3.unproject(this.camera)
          ltVector3.unproject(this.camera)
          cbVector3.unproject(this.camera)
//          cmVector3.unproject(this.camera)
          ctVector3.unproject(this.camera)
          rbVector3.unproject(this.camera)
          rmVector3.unproject(this.camera)
          rtVector3.unproject(this.camera)

          // 左右の視野角の算出
          const azimuthTheta = lmVector3.angleTo(rmVector3)
          const azimuthAngle = azimuthTheta / Math.PI * 180

          // 上下の視野角の算出
          const polarTheta = cbVector3.angleTo(ctVector3)
          const polarAngle = polarTheta / Math.PI * 180

          // ワールド座標正面から画面左下までの角度の算出
          const lbTheta = frontVector3.angleTo(lbVector3)
          const lbAngle = lbTheta / Math.PI * 180

          // ワールド座標正面から画面左上までの角度の算出
          const ltTheta = frontVector3.angleTo(ltVector3)
          const ltAngle = ltTheta / Math.PI * 180

          // ワールド座標正面から画面右下までの角度の算出
          const rbTheta = frontVector3.angleTo(rbVector3)
          const rbAngle = rbTheta / Math.PI * 180

          // ワールド座標正面から画面右上までの角度の算出
          const rtTheta = frontVector3.angleTo(rtVector3)
          const rtAngle = rtTheta / Math.PI * 180

          // 現在の視野角における最大回転角度の算出
          const limitedAzimuthAngle = (allowedAngle - azimuthAngle / 2) / 360 * Math.PI * 2
          const limitedPolarAngle = (allowedAngle - polarAngle / 2) / 360 * Math.PI * 2

          // 垂直・水平方向の回転角度制限は `OrbitControls` の以下のプロパティにより行う。
          //   - `maxAzimuthAngle`
          //   - `minAzimuthAngle`
          //   - `minPolarAngle`
          //   - `maxPolarAngle`

          // 左右方向の回転角度の制限
//          this.controls.maxAzimuthAngle = +limitedAzimuthAngle
//          this.controls.minAzimuthAngle = -limitedAzimuthAngle

          // 上下方向の回転角度の制限
//          this.controls.maxPolarAngle = Math.PI / 2 + limitedPolarAngle
//          this.controls.minPolarAngle = Math.PI / 2 - limitedPolarAngle

          // 垂直・水平方向の回転角度制限は `OrbitControls` の以下のプロパティにより行う。
          //   - `maxAzimuthAngle`
          //   - `minAzimuthAngle`
          //   - `minPolarAngle`
          //   - `maxPolarAngle`
          // 但し、斜め方向の回転角度制限は、上記のプロパティだけでは対応できない。
          // このため、表示中の画角の四隅が許可された範囲内からはみ出していないかどうかを判定している。
          // 収まっていない場合は、収まっていない角度分内側に、回転角度制限を設定している。

          // 左方向の回転角度の制限
          if (lbAngle >= allowedAngle) {
            this.controls.maxAzimuthAngle = this.controls.maxAzimuthAngle - (0.5 / 180 * Math.PI)
          } else if (ltAngle >= allowedAngle) {
            this.controls.maxAzimuthAngle = this.controls.maxAzimuthAngle - (0.5 / 180 * Math.PI)
          } else {
            this.controls.maxAzimuthAngle = +limitedAzimuthAngle
          }

          // 右方向の回転角度の制限
          if (rbAngle >= allowedAngle) {
            this.controls.minAzimuthAngle = this.controls.minAzimuthAngle + (0.5 / 180 * Math.PI)
          } else if (rtAngle >= allowedAngle) {
            this.controls.minAzimuthAngle = this.controls.minAzimuthAngle + (0.5 / 180 * Math.PI)
          } else {
            this.controls.minAzimuthAngle = -limitedAzimuthAngle
          }

          // 下方向の回転角度の制限
          if (lbAngle >= allowedAngle) {
            this.controls.minPolarAngle = this.controls.minPolarAngle + (0.5 / 180 * Math.PI)
          } else if (rbAngle >= allowedAngle) {
            this.controls.minPolarAngle = this.controls.minPolarAngle + (0.5 / 180 * Math.PI)
          } else {
            this.controls.minPolarAngle = Math.PI / 2 - limitedPolarAngle
          }

          // 上方向の回転角度の制限
          if (ltAngle >= allowedAngle) {
            this.controls.maxPolarAngle = this.controls.maxPolarAngle - (0.5 / 180 * Math.PI)
          } else if (rtAngle >= allowedAngle) {
            this.controls.maxPolarAngle = this.controls.maxPolarAngle - (0.5 / 180 * Math.PI)
          } else {
            this.controls.maxPolarAngle = Math.PI / 2 + limitedPolarAngle
          }
        }
      }, 30)
    }
  }

  _setRotateSpeed() {
    const rev = this.reverse ? -1 : 1
    this.controls.rotateSpeed = !this._inverseDirection ? 
      -1 * this._rotateSpeed * rev : 
      this._rotateSpeed * rev
  }

  /**
   * レンダリング処理
   * 
   * @private
   * 
   */
  _render = () => {
    this._renderer.setAnimationLoop( () => {
      this._renderer.render( this.scene, this.camera )
      this.controls.update()
    })
  }
}
